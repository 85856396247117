export interface IRequestResponse {
    error: boolean,
    response: {
        status: number,
        data: any
    }
}

enum EnumMethod {
    GET = "GET",
    POST = "POST",
}

export const get = async (data: TfnFetchData) => {
    return await fnFetch(EnumMethod.GET, data);
}
export const post = async (data: TfnFetchData) => {
    return await fnFetch(EnumMethod.POST, data);
}

type TfnFetchData = {
    route: string,
    token?: string | null,
    body?: any | null
}
const getCookie = (cname:string) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
const fnFetch = async (method: EnumMethod, {
    route,
    token = null,
    body = null
}: TfnFetchData) => {
    let response: IRequestResponse = {
        error: false,
        response: {
            status: 200,
            data: null
        }
    }
    if (route) {
        let headers: any = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "XSRF-TOKEN": getCookie("XSRF-TOKEN")
        };
        if (token) headers["Authorization"] = "Bearer " + token;
        const query = await fetch(route, {
            method,
            headers,
            body: body?JSON.stringify(body):null,
        });
        
        try {
            const status = query.status,
            data = await query.json();

            response.response = {status, data}
        } catch (error) {
            const status = query.status;
            console.error("[catch] error", error);
            response.error = true;
            response.response.status = status;
        }
    } else {
        console.error("[catch] [ROUTE] обязателен для заполнения");
        response.error = true;
    }
    return response;
}