import React, { FC, useEffect, useState } from "react";
import { TOrder } from "@entity/order";
import DefaultLayout from "@shared/layouts/default";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { TDefaultPageProps } from "pages";
import { Col, Row } from "react-grid-system";
import { isMobile } from "react-device-detect";
import { get } from "@shared/network";
import { getError } from "@shared/interaction";
import { RootState } from "@store";
import { connect } from "react-redux";
import Alert, { EnumAlertType } from "@shared/components/alert";
import { findById } from "@shared/helpers";
import { CaretLeft, CaretRight } from "react-iconly";

type TProps = {
    security: RootState["securityService"],
    places: RootState["places"]["items"]
} & TDefaultPageProps

type TState = {
    loading: boolean,
    visibleKey: number,
    orders: TOrder[],
    error: {
        visible: boolean,
        message: string
    },
}

const HistoryOrder: FC<TProps> = ({
    security, places
}) => {
    const [state, setState] = useState<TState>({
        loading: true,
        visibleKey: -1,
        orders: [],
        error: {
            visible: false,
            message: ""
        },
    });
    const [page, setPage] = useState({
        amount: 0,
        pageNum: 10,
        index: 1
    })

    const getData = async (pageIndex: number) => {
        const { response, error } = await get({ route: `api/order/orders/${pageIndex}`, token: security.token });
        if (!error && response.status === 200) {

            setState({ ...state, loading: false, orders: response.data.orders });

            setPage({
                index: pageIndex,
                amount: response.data.amount,
                pageNum: response.data.pageNum,
            })
        } else setState({
            ...state,
            loading: false,
            error: {
                visible: true,
                message: getError(response.data)
            }
        })
    },
        setVisibleKey = (key: number) => setState({ ...state, visibleKey: key });

    useEffect(() => {
        getData(1);
    }, []);

    const { loading, orders, visibleKey, error } = state,
        span = 2.4;

    return (
        <DefaultLayout title="История заказов" grayFooter={!loading && !error.visible ? true : false} loading={loading}>
            {error.visible ? (
                <Alert
                    icon="remind"
                    title="Что-то пошло не так. Попробуйте еще раз"
                    description={error.message}
                    type={EnumAlertType.danger} />
            ) : (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: `${isMobile ? '100%' : '80%'}`,
                    margin: '0 auto'
                }}>
                    <div className="history-order">
                        <Row className="header">
                            <Col className="column fitst-child" xs={span}>№ заказа</Col>
                            {!isMobile && (
                                <Col className="column" xs={span}>Ресторан</Col>
                            )}
                            <Col className="column" xs={span}>Дата</Col>
                            <Col className="column" xs={span}>Кол-во блюд</Col>
                            <Col className="column" xs={span}>Сумма заказа</Col>
                            {isMobile && (
                                <Col className="column" xs={span}></Col>
                            )}
                        </Row>
                        <div className="rows">
                            {orders.map((order: TOrder, idx) => {
                                const isVisible = visibleKey === idx,
                                    key = findById(places, order.place);

                                let placesName: string = "", placeLink: string = ""
                                if (key !== -1) {
                                    placesName = places[key].name;
                                    placeLink = places[key].link;
                                }

                                return (
                                    <div className={`row-collapse ${isVisible && "visible"}`} key={idx}>
                                        <Row className="row-main" onClick={() => setVisibleKey(isVisible ? -1 : idx)}>
                                            <Col className="column fitst-child" xs={span}>
                                                {order.number}
                                            </Col>
                                            {!isMobile && (
                                                <Col className="column" xs={span}>
                                                    {placesName}
                                                </Col>
                                            )}
                                            <Col className="column" xs={span}>
                                                {moment(order.date).format("DD.MM.YYYY")}
                                                {isMobile && (
                                                    <span>{placesName}</span>
                                                )}
                                            </Col>
                                            <Col className="column" xs={span}>{_.sumBy(order.orderItems, i => i.amount)}</Col>
                                            <Col className="column" xs={span}>
                                                {order.total + ' p.  '}
                                                {!isMobile && (
                                                    <i className="fas fa-angle-down"></i>
                                                )}
                                            </Col>
                                            {isMobile && (
                                                <Col className="column" xs={span}>
                                                    <i className="fas fa-angle-down"></i>
                                                </Col>
                                            )}
                                        </Row>
                                        {isVisible && (
                                            <div className={`collapse-content ${isVisible && "visible"}`}>
                                                {order.orderItems.map((item, i) => {
                                                    let ImageUrl: string = item.image;
                                                    if (item.image && ImageUrl.indexOf("https://") === -1 && ImageUrl.indexOf("http://") === -1) ImageUrl = placeLink + ImageUrl;

                                                    return (
                                                        <Row key={`${idx}-${i}`} className="collapse-content-row">
                                                            <Col className="column" xs={span * 2}>
                                                                {!isMobile && <div className="image" style={placeLink && item.image ? { backgroundImage: `url(${ImageUrl})` } : {}} />}
                                                                <div className="name">
                                                                    {item.name}
                                                                </div>
                                                            </Col>
                                                            <Col className="column" xs={span}>
                                                                x{item.amount}
                                                            </Col>
                                                            <Col className="column" xs={span}>
                                                                {`${item.price * item.amount} руб`}
                                                            </Col>
                                                        </Row>
                                                    )
                                                })}
                                                {order.total - order.sum > 10 ? (
                                                    <Row className="collapse-content-row">
                                                        <Col className="column" xs={span * 2}>
                                                            {!isMobile && <div className="image delivery" />}
                                                            <div className="name">
                                                                Доставка
                                                            </div>
                                                        </Col>
                                                        <Col className="column" xs={span}>
                                                            x1
                                                        </Col>
                                                        <Col className="column" xs={span}>
                                                            {`${order.total - order.sum} руб`}
                                                        </Col>
                                                    </Row>
                                                ) : order.sum - order.total > 0 ? (
                                                    <Row className="collapse-content-row">
                                                        <Col className="column" xs={span * 2}>
                                                            {!isMobile && <div className="image pickup" />}
                                                            <div className="name">
                                                                Скидка за самовывоз
                                                            </div>
                                                        </Col>
                                                        <Col className="column" xs={span}>

                                                        </Col>
                                                        <Col className="column" xs={span}>
                                                            -{`${order.sum - order.total} руб`}
                                                        </Col>
                                                    </Row>
                                                ) : <></>}
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="paginate">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={
                                <CaretRight set="bold" primaryColor="#444444" />
                            }
                            initialPage={page.index - 1}
                            onPageChange={page => {
                                getData(page.selected + 1)
                            }}
                            pageRangeDisplayed={isMobile? 3: 5}
                            marginPagesDisplayed={2}
                            pageCount={Math.ceil(page.amount / page.pageNum)}
                            previousLabel={
                                <CaretLeft set="bold" primaryColor="#444444" />
                            } />
                    </div>
                </div>
            )}
        </DefaultLayout>
    )
}

const mapState = (state: RootState) => ({
    security: state.securityService,
    places: state.places.items,
})
export default connect(mapState)(HistoryOrder);