import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "@store";
import {useFormik} from "formik";
import * as Yup from "yup";
import {setConfiguration} from "react-grid-system";
import {isMobile} from "react-device-detect";
import {TPassword, defaultPassword} from "@entity/profile";


const getStrength = (password: string) => {
    let errors: string[] = [];

    if (password.length < 6) {
        errors.push("Пароль должен содержать не менее 6 символов")
    }
    if (!/[0-9]/.test(password)) {
        errors.push("Пароль должен содержать хотябы одно число")
    }
    if (!/[a-z]/.test(password)) {
        errors.push("Пароль должен содержать строчную букву")
    }
    if (!/[A-Z]/.test(password)) {
        errors.push("Пароль должен содержать заглавную букву")
    }
    // if (!/[$&+,:;=?@#|"<>.^*()%!-]/.test(password)) {
    //     errors.push("Пароль должен содержать специальный символ")
    // }

    return errors
}


const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(`Поле "Старый пароль" обязательно для заполнения`),
    password: Yup.string().required(`Поле "Новый пароль" обязательно для заполнения`),
    passwordConfirm: Yup.string().required(`Поле "Подтверждение нового пароля" обязательно для заполнения`),
})

const ChangePasswordForm: React.FC<{
    setNewPassword?: Dispatch["profile"]["setNewPassword"]
}> = ({
    setNewPassword
}) => {
        const formik = useFormik({
            initialValues: defaultPassword,
            onSubmit: async (values: TPassword) => {
                formik.setSubmitting(true);
                let isValid = await validationSchema.isValid(values);
                if (isValid && setNewPassword) {
                    const err = getStrength(values.password);
                    if (err.length === 0) {
                        const status = await setNewPassword(values);
                        if (status) {
                            formik.resetForm({})
                        }
                    } else {
                        alert(err.join(`${"\n"}`))
                    }

                }
                formik.setSubmitting(false);
            },
        });
        setConfiguration({ gutterWidth: isMobile ? 10 : 25 });

        console.log();
        
        return (
            <div className="profile-form delivery-addresses">
                <div className="caption">Изменить пароль</div>
                <form onSubmit={formik.handleSubmit}>
                    <div className={`${!isMobile && "row"}`}>
                        <div className={`${!isMobile && "col"}`}>
                            <div className="form-group">
                                <label>Старый пароль</label>
                                <input type="text" name="oldPassword" value={formik.values.oldPassword} disabled={formik.isSubmitting} onChange={formik.handleChange} required={true} />
                            </div>
                        </div>
                        <div className={`${!isMobile && "col"}`}>
                            <div className="form-group">
                                <label>Новый пароль</label>
                                <input type="text" name="password" value={formik.values.password} disabled={formik.isSubmitting} onChange={formik.handleChange} required={true} />
                            </div>
                        </div>
                        <div className={`${!isMobile && "col"}`}>
                            <div className="form-group">
                                <label>Подтверждение нового пароля</label>
                                <input type="text" name="passwordConfirm" value={formik.values.passwordConfirm} disabled={formik.isSubmitting} onChange={formik.handleChange} required={true} />
                            </div>
                        </div>
                    </div>
                    <div className="button-container">
                        <button
                            data-badge="inline"
                            type="submit" disabled={formik.isSubmitting}>
                            Изменить пароль
                        </button>
                    </div>
                </form>
            </div>
        )
    }
const mapDispatch = (d: any) => {
    const dispatch: Dispatch = d;
    return {
        setNewPassword: dispatch.profile.setNewPassword,
    }
}
export default connect(null, mapDispatch)(ChangePasswordForm);