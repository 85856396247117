import React from "react"
import {connect} from "react-redux";
import Rodal from "rodal";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Dispatch, RootState} from "@store";
import {TChangePassword} from "@store/service/security";
import {PasswordInput} from "@shared/components/formInput";
import { isMobile } from "react-device-detect";

type TProps = {
    security: RootState["securityService"],
    fnChangePassword: Dispatch["securityService"]["changePassword"]
}

const initialValues: TChangePassword = {
    password: "",
    passwordConfirm: "",
},
validationSchema = Yup.object().shape({
    password: Yup.string().required("Поле обязательно для заполнения"),
    passwordConfirm: Yup.string().required("Поле обязательно для заполнения"),
});
// getErrorByField = (formik: any, field: string) => {
//     return formik.errors[field] && formik.touched[field] ? formik.errors[field] : undefined
// }

const ChangePassword: React.FC<TProps> = ({security, fnChangePassword}) => {
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values: TChangePassword) => {
            formik.setSubmitting(true)
            const status = await fnChangePassword(values);
            if (!status) formik.setSubmitting(false);
        },
    });
    return (
        <Rodal 
            visible={security.needChangePassword} onClose={() => {}} showCloseButton={false} className={isMobile? "mobile": undefined}>
            <div className="change-password-modal">
                <div className="form">
                    <div className="form-caption">Смена пароля</div>
                    <form onSubmit={formik.handleSubmit}>
                        <PasswordInput
                            placeholder="Пароль"
                            value={formik.values.password}
                            onChange={(value: string) => formik.setFieldValue("password", value, false)}
                            disabled={formik.isSubmitting}
                            // error={getErrorByField(formik, "password")}
                            />
                        <PasswordInput
                            placeholder="Подтверждение пароля"
                            value={formik.values.passwordConfirm}
                            onChange={(value: string) => formik.setFieldValue("passwordConfirm", value, false)}
                            disabled={formik.isSubmitting}
                            // error={getErrorByField(formik, "passwordConfirm")}
                            />
                        <div className="form-footer">
                            <button 
                                data-badge="inline"
                                type="submit" disabled={formik.isSubmitting}>
                                Сменить
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Rodal>
    )
}
const mapState = (state: RootState) => ({
    security: state.securityService,
}),
mapDispatch = (d: any) => {
    const dispatch = d as Dispatch;
    return {
        fnChangePassword: dispatch.securityService.changePassword,
    }
}
export default connect(mapState, mapDispatch)(ChangePassword);