import * as ls from "local-storage";
const appName = "@RestaLK";

async function save(request: string, dataSource: any) {
    const cacheAvailable = 'caches' in window;
    if (cacheAvailable) {
        const cache = await window.caches.open(appName);
        cache.put(request, new Response(JSON.stringify(dataSource)))
    } else {
        ls.set(`${appName}${request}`, JSON.stringify(dataSource));
    }
}

async function retrieve(request: string, defaultValue: any = undefined) {
    const cacheAvailable = 'caches' in window;
    if (cacheAvailable) {
        const cache = await window.caches.open(appName),
        response = await cache.match(request);

        if (response && response.status === 200) {
            const json = await response.json();
            return json;
        }
    } else {
        const value: any = ls.get(`${appName}${request}`);
        if (value !== null && typeof value === "string") {
            return JSON.parse(value);
        } else if (value !== null) return value
    }
    return defaultValue;
}

async function destroy(request: string) {
    const cacheAvailable = 'caches' in window;
    if (cacheAvailable) {
        const cache = await window.caches.open(appName);
        cache.delete(request);
    } else {
        ls.remove(`${appName}${request}`);
    }
    return true;
}

const DB = {
    save,
    retrieve,
    destroy
}
export default DB;