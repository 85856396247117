import {TOrderItem} from "./orderItem";

export type TOrder = {
    [index: string]: any,
    pickup: boolean,
    address: string, // Адрес доставки
    date: string,
    deliveryTime: string | null,
    deliveryTimeString: string, // Время доставки
    newDB: boolean,
    number: number, // Номер заказа
    place: number, // Id ресторана
    sum: number, // Сумма заказа
    total: number, // С учетом скидки или доставки
    bonuses: string | null, // Бонусы
    orderItems: TOrderItem[]
}

export type TBonuses = {
    date: string,
    place: number, // Id ресторана
    bonusAdd: number,
    bonusWriteOff: number,
    bonusAfter: number
}

export type TCard = {
    cardBarcode: string,
    cardNumber: string,
    restaurantId: number,
    sum: number,
    userId: number,
    hash: string,
    clientId:string,
    createdAt: string,
}

export const defaultOrder: TOrder = {
    pickup: false,
    address: "",
    date: "",
    deliveryTime: null,
    deliveryTimeString: "",
    newDB: false,
    number: 0,
    place: 0,
    sum: 0,
    total: 0,
    bonuses: null,
    orderItems: []
}

export const currentOrderDescription = [
    {caption: "Номер заказа", field: "number"},
    {caption: "Время принятия заказа", field: "date"},
    {caption: "Адрес доставки", field: "address"},
    {caption: "Время доставки", field: "deliveryTimeString"},
]