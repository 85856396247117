export type TProfile = {
    id: number,
    name: string,
    phone: string,
    email: string,
    birthDate: string | null,
}

export const defaultProfile: TProfile = {
    id: 0,
    name: "",
    phone: "",
    email: "",
    birthDate: ""
}

export type TPassword = {
    oldPassword: string, 
    password: string, 
    passwordConfirm: string
}

export const defaultPassword:TPassword = {
    oldPassword: "", 
    password: "", 
    passwordConfirm: ""
}