import _ from "lodash";

export const findById = (array: Array<any>, id: number) => {
    return _.findIndex(array, function(o: any) { return o.id === id})
}

export const cloneObject = (ovject: any): any => {
    var clone = JSON.stringify(ovject);
    return JSON.parse(clone);
};

export const parseBool = (str: any): boolean => {
    if(str === null) return false;

    if (typeof str === 'boolean') {
        return (str === true);
    }

    if(typeof str === 'string') {
        if(str === "") return false;

        str = str.replace(/^\s+|\s+$/g, '');
        if(str.toLowerCase() === 'true' || str.toLowerCase() === 'yes') return true;

        str = str.replace(/,/g, '.');
        str = str.replace(/^\s*\s*/g, '-');
    }

    if(!isNaN(str)) return (parseFloat(str) !== 0);

    return false;
}

export const twoObjectsAreEqual = (objectOne: any, objectTwo: any): boolean => {
    try {
        return JSON.stringify(objectOne) === JSON.stringify(objectTwo);
    } catch (e) {
        return false;
    }
}

export const isObject = (any: any): boolean => {
    return _.isObject(any);
}

export const valueExist = (value: any): boolean => {
    return !_.isNull(value) && !_.isEmpty(value) && !_.isUndefined(value)
}

export const isBlank = (str: any): boolean => {
    return (!str || /^\s*$/.test(str));
}

export const printErrorMessage = (data: any, status: number): string => {
    let content: any = `Ошибка #${status}`;

    if (data && !_.isUndefined(data.errors)) {
        content = data.errors;
        if (_.isObject(data.errors)) {
            content = Object.keys(data.errors).map((key: string) => {
                return data.errors[key];
            });
        }
    } else if (typeof data === "string") {
        content = data;
    }

    return content;
}

export const combineTwoObjectsByPattern = (pattern: any, current: any, modifies: any) => {
    const response = cloneObject(pattern);
    Object.keys(response).forEach((key: string) => {
        response[key] = modifies[key]?modifies[key]:(current[key]?current[key]:pattern[key])
    });
    return response;
}

export const arraySplice = (data: any[] = [], size: number = 4): Array<any[]> => {
    let arr: Array<any[]> = [], arrays = cloneObject(data);

    while (arrays.length > 0) {
        arr.push(arrays.splice(0, size));
    }

    return arr;
}