import create from "zustand"

interface RegistrationStore {
    phone: string
    setPhone: (val: string) => void
}

export const  useRegistration = create<RegistrationStore>(set => ({
    phone: "",
    setPhone: phone => set({phone})
}))