import React from "react";
import Form from "./form";
import {TDefaultPageProps} from "pages";
import DefaultLayout from "@shared/layouts/default";
import DeliveryAddresses from "./deliveryAddresses";
import ChangePasswordForm from "./changePassword";
import { isMobile } from "react-device-detect";

type TProps = {

} & TDefaultPageProps

class Profile extends React.PureComponent<TProps> {
    render = () => {
        return (
            <DefaultLayout title="Профиль" loading={false}>
                <div id="profile">
                    <Form />
                    <ChangePasswordForm />
                    <DeliveryAddresses />
                </div>
            </DefaultLayout>
        )
    }
}

export default Profile;