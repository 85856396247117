import {createModel} from "@rematch/core";
import type {RootModel} from "../root";
import * as signalR from "@microsoft/signalr";
import { TCodeModel } from "pages/security/_confirmModal";

type TModelSocket = {
    connectionId: null | string
}

export const socketService = createModel<RootModel>()({
    name: "socketService",
    state: {
        connectionId: null
    } as TModelSocket,
    reducers: {
        setConnectionId(state: TModelSocket, payload: string) {
            return {
                ...state,
                connectionId: payload
             };
        },
    },
    effects: (dispatch) => ({
        init(payload: object, state) {
            const connection = new signalR.HubConnectionBuilder()
            .withUrl("/ws")
            .configureLogging(signalR.LogLevel.Information)
            .build();

            connection.on("ReceiveMessageOne", (response: any) => {
                const payload = response as TCodeModel;
                dispatch.securityService.confirm(payload)
            })

            connection.start()
            .then(() => {
                connection.invoke('getConnectionId')
                .then((connectionId) => {
                    dispatch.socketService.setConnectionId(connectionId)
                }).catch(err => {
                    console.error("connected", err.toString())
                });
            }).catch((err: any) => {
                console.error("catch", err.toString());
            });
        }
    })
})