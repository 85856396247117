import React, { FC, useEffect, useState } from "react";
import DefaultLayout from "@shared/layouts/default";
import { TDefaultPageProps } from "pages";
import { currentOrderDescription, defaultOrder, TOrder } from "@entity/order";
import { isMobile } from "react-device-detect";
import { RootState } from "@store";
import { connect } from "react-redux";
import { get } from "@shared/network";
import { getError } from "@shared/interaction";
import Alert, { EnumAlertType } from "@shared/components/alert";
import moment from "moment";
import _ from "lodash";
import { arraySplice } from "@shared/helpers";
import lodash from "lodash";

type TProps = {
    security: RootState["securityService"],
    places: RootState["places"]["items"]
} & TDefaultPageProps

type TState = {
    loading: boolean,
    order: TOrder,
    error: {
        visible: boolean,
        message: string
    },
}

const CurrentOrder: FC<TProps> = ({
    security, places
}) => {
    const [state, setState] = useState<TState>({
        loading: true,
        order: defaultOrder,
        error: {
            visible: false,
            message: ""
        },
    });
    const [status, setStatus] = useState<string | null>(null);

    const getStatus = async (restaurantId: number, orderId: number) => {
        // setTimeout(async () => {
            const { response, error } = await get({ route: `api/order/status/${restaurantId}/${orderId}`, token: security.token });
            if (!error && response.status === 200) {
                try {
                    setStatus(response.data.status);
                } catch (error) {

                }
            }
            // getStatus(restaurantId, orderId);
        // }, 15000)
    }

    const getData = async (): Promise<void> => {
        const { response, error } = await get({ route: `api/order/current`, token: security.token });
        if (!error && response.status === 200) {
            const order: TOrder = response.data;

            if (order.number > 0 && order?.status) setStatus(order.status);

            if (order.number > 0) getStatus(order.place, order.number);
            setState({ ...state, order, loading: false });
        } else setState({
            ...state,
            loading: false,
            error: {
                visible: true,
                message: getError(response.data)
            }
        })
    }

    useEffect(() => {
        getData();
    }, []);

    const { loading, order, error } = state,
        discount = order.sum - order.total,
        placesSplice = arraySplice(places),
        placeKey = lodash.findIndex(places, p => p.id === order.place);

    return (
        <DefaultLayout title="Текущий заказ" loading={loading}>
            {error.visible ? (
                <Alert
                    icon="remind"
                    title="Что-то пошло не так. Попробуйте еще раз"
                    description={error.message}
                    type={EnumAlertType.danger} />
            ) : order.number === 0 ? (
                <div className="current-order-empty">
                    <div className="header">
                        <div className="dish" />
                        <div className="caption">В вашей тарелке пусто</div>
                        <div className="signature">Чтобы сделать заказ, выберите ресторан</div>
                    </div>
                    <div className="places-content">
                        {placesSplice.map((splice, idx) => (
                            <div className="places" key={idx}>
                                {splice.map((place, i) => (
                                    <div key={`${idx}-${i}`} className="place">
                                        <a className={`bg-${place.id}`} href={place.link} target="_blank" rel="noopener noreferrer" title={place.name}>
                                            {place.name}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="current-order">
                    <div className="information">
                        <div>
                            {status && (
                                <div className="information-row">
                                    <div className="caption">Статус заказа:</div>
                                    <div className="value" style={{ textTransform: "capitalize", fontWeight: "bold" }}>{status}</div>
                                </div>
                            )}
                            {placeKey !== -1 && (
                                <div className="information-row">
                                    <div className="caption">Заведение:</div>
                                    <div className="value">
                                        <a href={places[placeKey].link} target="_blank" rel="noopener noreferrer" title={places[placeKey].name}>
                                            {places[placeKey].name}
                                        </a>
                                    </div>
                                </div>
                            )}
                            {currentOrderDescription.map((val, idx) => {
                                if (val.field === "deliveryTimeString") return <></>;

                                if (val.field === "address" && order.pickup) return <></>;
                                if (val.field === "deliveryTimeString" && !order[val.field]) return <></>;
                                let caption = val.caption, value = order[val.field];

                                if (val.field === "date") value = moment(order[val.field]).format("DD.MM.YYYY HH:mm");
                                if (val.field === "deliveryTimeString") caption = "Время самовывоза";

                                return (
                                    <div className="information-row" key={idx}>
                                        <div className="caption">{caption}:</div>
                                        <div className="value">{value}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="products">
                        <table cellSpacing="0" cellPadding="0">
                            <tbody>
                                {order.orderItems.map((item, idx) => {
                                    const key = _.findIndex(places, p => p.id === order.place);
                                    let srcImage = item.image;
                                    if (key !== -1 && srcImage.indexOf(places[key].link) === -1) {
                                        srcImage = places[key].link + srcImage;
                                    }
                                    return (
                                        <>
                                            <tr key={idx}>
                                                {!isMobile && (
                                                    <td className="td-image">
                                                        <div className="image"
                                                            style={{ backgroundImage: `url(${srcImage})` }} />
                                                    </td>
                                                )}
                                                <td className="name">
                                                    {item.name}
                                                </td>
                                                <td className="amount">
                                                    x{item.amount}
                                                </td>
                                                <td className="price">
                                                    {item.price * item.amount} руб
                                                </td>
                                            </tr>
                                            <tr key={`${idx}-0`}>
                                                <td className="separator" colSpan={4}></td>
                                            </tr>
                                        </>
                                    )
                                })}
                                {order.sum === order.total && order.pickup ? (
                                    <tr>
                                        <td className="sum" colSpan={isMobile ? 3 : 4}>
                                            Сумма к оплате: <span>{order.total}</span> руб
                                        </td>
                                    </tr>
                                ) : order.sum === order.total && !order.pickup ? (
                                    <>
                                        <tr>
                                            <td className="total-sum" colSpan={isMobile ? 3 : 4}>
                                                Сумма заказа: <span>{order.sum}</span> руб
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="discount" colSpan={isMobile ? 3 : 4}>
                                                Доставка: <span>Бесплатно!</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="sum" colSpan={isMobile ? 3 : 4}>
                                                Сумма к оплате: <span>{order.total}</span> руб
                                            </td>
                                        </tr>
                                    </>
                                ) : (
                                    <>
                                        <tr>
                                            <td className="total-sum" colSpan={isMobile ? 3 : 4}>
                                                Сумма заказа: <span>{order.sum}</span> руб
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="discount" colSpan={isMobile ? 3 : 4}>
                                                {discount > 0 ? (
                                                    <>
                                                        Скидка: <span>{discount}</span> руб
                                                    </>
                                                ) : (
                                                    <>
                                                        Доставка: <span>{Math.abs(discount)}</span> руб
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="sum" colSpan={isMobile ? 3 : 4}>
                                                Сумма к оплате: <span>{order.total}</span> руб
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                        <div className="cancel-order">
                            {/* <button>Отменить заказ</button> */}
                        </div>
                    </div>
                </div>
            )}
        </DefaultLayout>
    )
}

const mapState = (state: RootState) => ({
    security: state.securityService,
    places: state.places.items,
})
export default connect(mapState)(CurrentOrder);