import { Link, LinkGetProps } from "@reach/router";
import React from "react"
import { TNav } from "..";

type TProps = {
    navigations: TNav[],
    isMobile: boolean
}

const isActive = ({ isCurrent }: LinkGetProps, isMobile: boolean, item: TNav) => {
    if (!isMobile) return isCurrent ? { className: "active" } : {}
    else return isCurrent ? { className: `active ${item.icon}` } : { className: `${item.icon}` }
}

const DesktopNav: React.FC<TProps> = ({
    navigations, isMobile
}) => {
    return (
        <nav>
            {navigations.map((val: TNav, idx: number) => {
                const getProps = (props: LinkGetProps) => isActive(props, isMobile, val);
                return (
                    <>
                        <div key={idx}>
                            {isMobile ? (
                                <Link to={val.to} getProps={getProps} />
                            ) : (
                                <Link to={val.to} getProps={getProps}>
                                    {val.name}
                                </Link>
                            )}
                        </div>
                        {(idx + 1) != navigations.length && !isMobile && <div>|</div>}
                    </>
                )
            })}
        </nav>
    )
}

export default DesktopNav;