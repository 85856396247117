import React from "react";
import {connect} from "react-redux";
import {Dispatch, RootState} from "@store";
import {defaultDeliveryAddresses, TDeliveryAddresses} from "@entity/deliveryAddresses";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Col, Row, setConfiguration} from "react-grid-system";
import {isMobile} from "react-device-detect";

type TProps = {
    loading: RootState["deliveryAddresses"]["loading"],
    items: RootState["deliveryAddresses"]["items"],
    fnAddAddress: Dispatch["deliveryAddresses"]["add"],
    fnDeleteAddress: Dispatch["deliveryAddresses"]["delete"],
}

const fields = [
    {name: "house", caption: "Дом", type: "text", required: true, span: isMobile?12:2},
    {name: "housing", caption: "Корпус", type: "text", required: false, span: isMobile?6:2},
    {name: "intercom", caption: "Домофон", type: "text", required: false, span: isMobile?6:2},
    {name: "entrance", caption: "Подъезд", type: "text", required: false, span: isMobile?6:2},
    {name: "flat", caption: "Квартира", type: "text", required: false, span: isMobile?12:2},
    {name: "floor", caption: "Этаж", type: "number", required: false, span: isMobile?6:2},
];

const validationSchema = Yup.object().shape({
    city: Yup.string().required(`Поле "Город" обязательно для заполнения`),
    street: Yup.string().required(`Поле "Улица" обязательно для заполнения`),
    house: Yup.string().required(`Поле "Дом" обязательно для заполнения`),
})

const AddressForm: React.FC<{
    fnAddAddress: Dispatch["deliveryAddresses"]["add"]
}> = ({
    fnAddAddress
}) => {
    const formik = useFormik({
        initialValues: defaultDeliveryAddresses,
        onSubmit: async (values: TDeliveryAddresses) => {
            formik.setSubmitting(true);
            let isValid = await validationSchema.isValid(values);
            if (isValid && fnAddAddress) {
                const status = await fnAddAddress(values);
                if (status) {
                    formik.resetForm({})
                }
            }
            formik.setSubmitting(false);
        },
    });
    setConfiguration({ gutterWidth: isMobile?10:25 });
    return (
        <form onSubmit={formik.handleSubmit}>
            <Row>
                <Col xs={isMobile?12:6}>
                    <input name="city" value={formik.values.city} onChange={formik.handleChange} placeholder="Город *" disabled={formik.isSubmitting} required={true} />
                </Col> 
                <Col xs={isMobile?12:6}>
                    <input name="street" value={formik.values.street} onChange={formik.handleChange} placeholder="Улица *" disabled={formik.isSubmitting} required={true} />
                </Col> 
            </Row>
            <Row>
                {fields.map((field, idx) => {
                    let placeholder = field.caption;
                    if (field.required) placeholder += " *";
                    return (
                        <Col xs={field.span} key={idx}>
                            <input 
                                name={field.name}
                                value={formik.values[field.name]}
                                onChange={formik.handleChange}
                                placeholder={placeholder}
                                type={field.type}
                                required={field.required}
                                disabled={formik.isSubmitting} />
                        </Col> 
                    )
                })}
            </Row>
            <Row>
                <Col xs={12}>
                    <button data-badge="inline" type="submit" disabled={formik.isSubmitting}>
                        Сохранить
                    </button>
                </Col>
            </Row>
        </form>
    )
}

const DeliveryAddresses: React.FC<TProps> = ({
    loading, items, fnAddAddress, fnDeleteAddress
}) => {
    if (loading) return <></>;
    return (
        <div className="delivery-addresses">
            {items.length > 0 && (
                <div className="saved-addresses">
                    <div className="caption">Адреса доставки</div>
                    <ul>
                        {items.map((item, idx) => {
                            let address = `${idx + 1}. ${item.city}, ул. ${item.street}`;
                            if (item.housing) address += `, корп. ${item.housing}`;
                            address += `, д. ${item.house}`;
                            if (item.flat) address += `, кв. ${item.flat}`;
                            
                            return (
                                <li key={idx}>
                                    <span>{address}</span>
                                    <button type="button" onClick={() => fnDeleteAddress(item.id)}>
                                        <i className="far fa-trash-alt"></i>
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
            <div className="new-addresses">
                <div className="caption">Добавить адрес</div>
                <AddressForm fnAddAddress={fnAddAddress} />
            </div>
        </div>
    )
}

const mapState = (state: RootState) => ({
    loading: state.deliveryAddresses.loading,
    items: state.deliveryAddresses.items,
}),
mapDispatch = (d: any) => {
    const dispatch: Dispatch = d;
    return {
        fnAddAddress: dispatch.deliveryAddresses.add,
        fnDeleteAddress: dispatch.deliveryAddresses.delete,
    }
}
export default connect(mapState, mapDispatch)(DeliveryAddresses);