import {createModel} from "@rematch/core";
import type {RootModel} from "../root";
import {defaultProfile, TPassword, TProfile} from "@entity/profile";
import {get, post} from "@shared/network";
import {getError, alert} from "@shared/interaction";
import { cloneObject } from "@shared/helpers";
import moment from "moment";

type TModelProfile = {
    loading: boolean,
    item: TProfile
}

export type TSaveProfile = {
    iAgree: boolean
} & TProfile

export const profile = createModel<RootModel>()({
    name: "profile",
    state: {
        loading: true,
        item: defaultProfile
    } as TModelProfile,
    reducers: {
        update(state: TModelProfile, payload: object) {
            return {...state, ...payload};
        },
    },
    effects: (dispatch) => ({
        async getData(payload: object, state): Promise<boolean> {
            const {securityService} = state,
            {response, error} = await get({route: `api/profile`, token: securityService.token});
            if (!error && response.status === 200) {
                dispatch.profile.update({item: response.data, loading: false})
                dispatch.deliveryAddresses.init(response.data.addresses)
                return true;
            } else return false;
        },
        async save(payload: TProfile, state): Promise<boolean> {
            const {securityService} = state;

            let body = cloneObject(payload);
            if (body.birthDate) body.birthDate = moment(body.birthDate, "DD.MM.YYYY").format("YYYY-MM-DD")
            delete body.iAgree;
            delete body.addresses;

            const {response, error} = await post({route: `api/profile`, body, token: securityService.token});
            if (!error && response.status === 200) {
                await dispatch.profile.update({item: payload})
                return true;
            } else {
                const message: string = getError(response.data);
                alert(message);
            }
            return false;
        },
        async setNewPassword(payload: TPassword, state): Promise<boolean> {
            let body = cloneObject(payload);
            const {securityService} = state;

            const {response, error} = await post({route: `api/auth/setNewPassword`, body, token: securityService.token});
            if (!error && response.status === 200) {
                const data: {message: string} = response.data;
                alert(data.message);
                return true;
            } else {
                const message: string = getError(response.data);
                alert(message);
            }
            return false;
        },
    })
})