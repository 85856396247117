import { EnumSenderSecurity } from "@store/service/security";
import { Formik } from "formik";
import moment from "moment";
import React from "react";
import InputMask from "react-input-mask";
import { DatePicker } from "rsuite";
import styled from "styled-components";

type TInput = {
    value: string,
    onChange: (value: string) => void,
    placeholder: string,
    disabled?: boolean,
    error?: string
    name?: string
    type?: string
}

export const PhoneInput: React.FC<TInput> = ({
    value, placeholder, onChange, disabled, error, name
}) => {
    return (
        <div className="form-group">
            <div className={`phone-input ${error && "danger-input"}`}>
                <InputMask
                    mask="+7 (999) 999 99 99"
                    placeholder={placeholder}
                    name={name? name: 'phone'}
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                    disabled={disabled}>
                    {(inputProps: any) => <input {...inputProps} />}
                </InputMask>
            </div>
            <div className="error">{error}</div>
        </div>
    )
}

const CustomDatePicker = styled(DatePicker)`
    width: 100%;

    .rs-btn-default {
        border-radius: 0;

        &:hover {
            border-color: #dbdbdb!important;
        }

        .rs-picker-toggle-value {
            color: #030306;
        }
    }
`;

export const DateInput: React.FC<TInput> = ({
    value, placeholder, onChange, disabled, error, name, type
}) => {
    if (type === "hidden") return <input type="hidden" name={name} value={value} />
    return (
        <div className="form-group">
            <CustomDatePicker
                disabled={disabled}
                placeholder={placeholder}
                value={value? moment(value, "D.MM.YYYY").toDate(): undefined}
                onChange={(evt: Date) => {
                    onChange(moment(evt).format("D.MM.YYYY"))
                }}
                format="D.MM.YYYY"
                cleanable={false}
                />
        </div>
    )
}

export const PasswordInput: React.FC<TInput> = ({
    value, placeholder, onChange, disabled, error
}) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    return (
        <div className="form-group">
            <div className={`password-input ${error && "danger-input"}`}>
                <input
                    placeholder={placeholder}
                    type={visible ? "text" : "password"}
                    name="password"
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                    disabled={disabled} />
                <div className="password-eye" onClick={() => setVisible(!visible)}>
                    <i className={`far ${visible ? "fa-eye" : "fa-eye-slash"}`}></i>
                </div>
            </div>
            <div className="error">{error}</div>
        </div>
    )
}

type TCheckboxInput = {
    checked: boolean,
    label: string | JSX.Element,
    onChange: (checked: boolean) => void
    disabled: boolean
}
export const CheckboxInput: React.FC<TCheckboxInput> = ({
    label, checked, onChange, disabled
}) => {
    return (
        <div className="form-group">
            {disabled ? (<div className={`checkbox disabled`}>
                <label className="container">{label}
                    <input type="checkbox" name="disabled" checked={checked} onChange={e => onChange(e.target.checked)} />
                    <span className="checkmark"></span>
                </label>
            </div>) : (
                <div className={`checkbox`}>
                    <label className="container">{label}
                        <input type="checkbox" name="remember" checked={checked} onChange={e => onChange(e.target.checked)} />
                        <span className="checkmark"></span>
                    </label>
                </div>
            )}
        </div>
    )

}
type TConfirmationButtons = {
    value: EnumSenderSecurity,
    onChange: (value: EnumSenderSecurity) => void,
    disabled?: number[],
    error?: string
}
export const ConfirmationButtons: React.FC<TConfirmationButtons> = ({
    value,
    onChange,
    disabled,
    error
}) => {
    let isDisabledTelegram = false,
        // isDisabledWhatsapp = false,
        isDisabledSMS = false;

    if (disabled && disabled.length > 0) {
        isDisabledTelegram = disabled.includes(EnumSenderSecurity.Telegram);
        // isDisabledWhatsapp = disabled.includes(EnumSenderSecurity.Whatsapp);
        isDisabledSMS = disabled.includes(EnumSenderSecurity.COMMON_SMS);
    }

    return (
        <div className={`confirmation ${error && "danger-input"}`}>
            <div className="caption">Отправить код подтверждения:</div>
            <div className="main-sender">
                <div className="sender-col wp-100">
                    <div className={`sender ${isDisabledSMS && "disabled"} sms ${value === EnumSenderSecurity.COMMON_SMS && "selected"}`} onClick={() => !isDisabledSMS && onChange(EnumSenderSecurity.COMMON_SMS)}>
                        <span>SMS</span>
                    </div>
                </div>
                {/* <div className="sender-col">
                    <div className={`sender ${isDisabledWhatsapp && "disabled"} whatsapp ${value === EnumSenderSecurity.Whatsapp && "selected"}`} onClick={() =>  !isDisabledWhatsapp && onChange(EnumSenderSecurity.Whatsapp)}>
                        <i className="fab fa-whatsapp"></i>
                        <span>WhatsApp</span>
                    </div>
                </div> */}
            </div>
            {/* <div className={`sender ${isDisabledTelegram && "disabled"} telegram ${value === EnumSenderSecurity.Telegram && "selected"}`} onClick={() => !isDisabledTelegram && onChange(EnumSenderSecurity.Telegram)}>
                <i className="fab fa-telegram-plane"></i>
                <span>Telegram</span>
            </div> */}
            <div className="error">{error}</div>
        </div>
    )
}