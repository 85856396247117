import React, { FC, useEffect, useState } from "react";
import { TCard } from "@entity/order";
import DefaultLayout from "@shared/layouts/default";
import _ from "lodash";
import moment from "moment";
import { TDefaultPageProps } from "pages";
import { Col, Row } from "react-grid-system";
import { isMobile } from "react-device-detect";
import { get } from "@shared/network";
import { getError } from "@shared/interaction";
import { RootState } from "@store";
import { connect } from "react-redux";
import Alert, { EnumAlertType } from "@shared/components/alert";
import { findById } from "@shared/helpers";

type TProps = {
    security: RootState["securityService"],
    places: RootState["places"]["items"]
} & TDefaultPageProps

type TState = {
    loading: boolean,
    visibleKey: number,
    card: TCard[],
    error: {
        visible: boolean,
        message: string
    },
}

const GiftСard: FC<TProps> = ({
    security, places
}) => {
    const [state, setState] = useState<TState>({
        loading: true,
        visibleKey: -1,
        card: [],
        error: {
            visible: false,
            message: ""
        },
    });
    const [page, setPage] = useState({
        amount: 0,
        pageNum: 10,
        index: 1
    })

    const [onlyElectric, setOnlyElectric] = useState<boolean>(true)

    const getData = async (pageIndex: number) => {
        setState({
            loading: true,
            visibleKey: -1,
            card: [],
            error: {
                visible: false,
                message: ""
            },
        })
        const { response, error } = await get({ route: `api/bonus/deposits?onlyElectr=${onlyElectric}`, token: security.token });
        if (!error && response.status === 200) {
            setState({ ...state, loading: false, card: response.data });

            setPage({
                index: pageIndex,
                amount: response.data.amount,
                pageNum: response.data.pageNum,
            })
        } else setState({
            ...state,
            loading: false,
            error: {
                visible: true,
                message: getError(response.data)
            }
        })
    },
        setVisibleKey = (key: number) => setState({ ...state, visibleKey: key });

    useEffect(() => {
        getData(1);
    }, [onlyElectric]);

    const { loading, card, visibleKey, error } = state,
        span = isMobile ? 3.5 : 2;

    return (
        <DefaultLayout title="Подарочные сертификаты" grayFooter={!loading && !error.visible ? true : false} loading={loading}>
            {error.visible ? (
                <Alert
                    icon="remind"
                    title="Что-то пошло не так. Попробуйте еще раз"
                    description={error.message}
                    type={EnumAlertType.danger} />
            ) : (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: `${isMobile ? '100%' : '80%'}`,
                    margin: '0 auto'
                }}>
                    <div className="text-buy">
                        <a href={'https://cards.resta.rest/'} target="_blank">Вы можете приобрести подарочные сертификаты по этой ссылке</a>
                    </div>
                    <div className="history-order">
                        {!isMobile && (
                            <div className="select-wrapper">
                                <div className="text-buy" style={{marginBottom: 0, fontSize: 19}}>
                                    Электронные подарочные сертификаты
                                </div>
                                <select className="select" value={onlyElectric.toString()} onChange={() => setOnlyElectric(!onlyElectric)}>
                                    <option value="false">Все электронные сертификаты</option>
                                    <option value="true">Действующие электронные сертификаты</option>
                                </select>
                            </div>
                        )}
                        <Row className="header">
                            <Col className="column" xs={span}>Дата покупки</Col>
                            {!isMobile && <Col className="column" xs={span}>Ресторан</Col>}
                            <Col className="column" xs={span}>Номер карты</Col>
                            <Col className="column" xs={span}>Баланс</Col>
                            {!isMobile &&
                                <>
                                    <Col className="column" xs={span}>Дата окончания действия</Col>
                                    <Col className="column" xs={span}>Ссылка на электр. сертификат</Col>
                                </>}
                        </Row>
                        {isMobile && (
                            <div className="select-wrapper">
                                <select className="select" value={onlyElectric.toString()} onChange={() => setOnlyElectric(!onlyElectric)}>
                                    <option value="false">Все электронные подарочные сертификаты</option>
                                    <option value="true">Действующие электронные сертификаты</option>
                                </select>
                            </div>
                        )}
                        {card.length === 0 && (
                            <div className="text-buy">
                                У Вас нет электронных <br /> подарочных сертификатов.
                            </div>
                        )}
                        <div className="rows">
                            {card.map((order: TCard, idx) => {
                                const isVisible = visibleKey === idx,
                                    key = findById(places, order.restaurantId);

                                let placesName: string = "", placeLink: string = ""
                                if (key !== -1) {
                                    placesName = places[key].name;
                                    placeLink = places[key].link;
                                }

                                return (
                                    <div className={`row-collapse ${isVisible && "visible"}`} key={idx}>
                                        <Row className="row-main" onClick={() => setVisibleKey(isVisible ? -1 : idx)}>
                                            {/* <Col className="column fitst-child" xs={span}>
                                                {order.number}
                                            </Col> */}
                                            <Col className="column" xs={span}>
                                                {moment(order.createdAt).format("DD.MM.YYYY")}
                                                {isMobile && (
                                                    <span>{placesName}</span>
                                                )}
                                            </Col>
                                            {!isMobile && <Col className="column" xs={span}>{placesName}</Col>}
                                            <Col className="column" xs={span}>{order.cardBarcode}</Col>
                                            <Col className="column" xs={span}>
                                                {order.sum} р.
                                            </Col>
                                            {!isMobile && (
                                                <>
                                                    <Col className="column" xs={span}>
                                                        {moment(order.createdAt).add(1, 'year').format("DD.MM.YYYY")}
                                                    </Col>
                                                    <Col className="column" xs={span}>
                                                        <a target="_blank" href={'https://form.p-h.app/plug/' + order.hash} >Загрузить сертификат</a>
                                                    </Col>
                                                </>
                                            )}
                                            {isMobile && <Col className="column" xs={1}>
                                                <i className="fas fa-angle-down"></i>
                                            </Col>}
                                        </Row>
                                        {isVisible && isMobile && (
                                            <div className={`collapse-content ${isVisible && "visible"}`}>
                                                <Row className="collapse-content-row">
                                                    <Col className="column" xs={12} style={{ flexDirection: 'column' }}>
                                                        <a target="_blank" style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: 5 }} href={'https://form.p-h.app/plug/' + order.hash} >Загрузить сертификат</a>
                                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                            Дата окончания действия: <b style={{ marginLeft: 5 }}>{' ' + moment(order.createdAt).add(1, 'year').format("DD.MM.YYYY")}</b>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* <div className="paginate">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={
                            <CaretRight set="bold" primaryColor="#444444"/>
                        }
                        initialPage={page.index - 1}
                        onPageChange={page => {
                            getData(page.selected + 1)
                        }}
                        pageRangeDisplayed={page.pageNum}
                        pageCount={Math.ceil(page.amount / page.pageNum)}
                        previousLabel={
                            <CaretLeft set="bold" primaryColor="#444444"/>
                        }/>
                </div> */}
                </div>
            )}
        </DefaultLayout>
    )
}

const mapState = (state: RootState) => ({
    security: state.securityService,
    places: state.places.items,
})
export default connect(mapState)(GiftСard);