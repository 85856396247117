export type TBonus = {
    bonusProcent: number,
    bonusRemainder: number,
    bonusSum: number,
    cardHash: string | null,
    cardNumber: string | null,
    restaurant: number
}

export const defaultBonus: TBonus = {
    bonusProcent: 0,
    bonusRemainder: 0,
    bonusSum: 0,
    cardHash: null,
    cardNumber: null,
    restaurant: 0
}