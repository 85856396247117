import { navigate } from "@reach/router";
import { createModel } from "@rematch/core";
import { cloneObject } from "@shared/helpers";
import { getError, alert } from "@shared/interaction";
import { post } from "@shared/network";
import DB from "@shared/storageDB";
import { Dispatch } from "@store";
import { TCodeModel } from "pages/security/_confirmModal";
import type { RootModel } from "../root";

type TSecurityService = {
    id: number,
    token: string,
    isAuthorized: boolean,
    isReady: boolean,
    needChangePassword: boolean,
}
export enum EnumSenderSecurity {
    UnKnow = 0,
    Telegram = 1,
    Whatsapp = 2,
    SMS = 3,
    COMMON_SMS = 4,
}
export type TLoginPayload = {
    phone: string,
    password: string,
    remember: boolean
    reCAPTCHA?: string;
}

export type TLoginPayloadV2 = {
    phone: string,
    reCAPTCHA?: string;
    agreement: boolean,
}

export type TRegistrationPayload = {
    id: string,
    passwordConfirm: string,
    sender: EnumSenderSecurity,
    reCAPTCHA: string,
    agreement: boolean

    email: string
    name: string
} & TLoginPayload
export type TForgotPasswordPayload = {
    phone: string,
    sender: EnumSenderSecurity,
    reCAPTCHA: string
}
export type PromiceConfirm = {
    id?: string,
    link?: string,
    message?: string,
    error: boolean
}

export type TChangePassword = {
    password: string,
    passwordConfirm: string,
}

const saveState = async (data: {
    authData: {
        id: number,
        token: string,
    }
}, _dispatch: Dispatch, needChangePassword: boolean): Promise<void> => {
    const state: TSecurityService = cloneObject(initialState);
    state.id = data.authData.id;
    state.token = data.authData.token;
    state.isAuthorized = true;
    state.isReady = true;
    DB.save(":Security", state);

    state.needChangePassword = needChangePassword;

    await _dispatch.securityService.setData(state);
    _dispatch.profile.getData({});
    navigate("/");
}

const initialState: TSecurityService = {
    id: 0,
    token: "",
    isAuthorized: false,
    isReady: false,
    needChangePassword: false,
}
export const securityService = createModel<RootModel>()({
    name: "securityService",
    state: initialState as TSecurityService,
    reducers: {
        setData(state, payload: object) {
            return { ...state, ...payload };
        }
    },
    effects: _dispatch => ({
        async init(payload: object, rootState): Promise<void> {
            const storage: TSecurityService = await DB.retrieve(":Security", initialState);
            if (storage.isAuthorized) {
                await _dispatch.securityService.setData({ token: storage.token });
                if (await _dispatch.profile.getData({})) {
                    _dispatch.securityService.setData({ isReady: true, isAuthorized: true });
                    if (!window.location.pathname.includes("/card")
                        && window.location.pathname !== "/write-offs-of-bonuses"
                        && window.location.pathname !== "/history"
                        && window.location.pathname !== "/profile"
                        && window.location.pathname !== "/bonus-program"
                        && window.location.pathname !== "/gift-card"
                        && !window.location.pathname.includes("/link")
                        && !window.location.pathname.includes("/vlink")) {
                        navigate("/");
                    }

                } else {
                    _dispatch.securityService.setData({ ...initialState, isReady: true });
                    _dispatch.securityService.logout({})
                }
            } else {
                await _dispatch.securityService.setData({ isReady: true });
                if (!window.location.pathname.includes("/card") && !window.location.pathname.includes("/link") && !window.location.pathname.includes("/vlink")) {
                    navigate("/login");
                }
            }
            return;
        },
        async login(payload: TLoginPayload, rootState): Promise<boolean> {
            let body = {
                // login: `+7 ${payload.phone}`,
                login: payload.phone,
                password: payload.password,
                reCAPTCHA: payload.reCAPTCHA
            }
            const { response, error } = await post({ route: `api/auth`, body });
            if (!error && response.status === 200) {
                await saveState(response.data, _dispatch, rootState.securityService.needChangePassword)
                return true;
            } else {
                const message: string = getError(response.data);
                alert(message);
            }
            return true;
        },
        async loginV2(payload: TLoginPayloadV2, rootState): Promise<{
            message?: string,
            needToRegister?: boolean,
            error: boolean
        }> {
            let body = {
                phone: payload.phone,
                reCAPTCHA: payload.reCAPTCHA
            }

            const { response, error } = await post({ route: `api/auth/byphone`, body });
            console.log(body, response, error);

            if (!error && response.status === 200) {
                // await saveState(response.data, _dispatch, rootState.securityService.needChangePassword)
                const data: { message: string } = response.data;
                return { message: data.message, error: false };
            } else if (response.status == 404) {
                return { error: true, needToRegister: true }
            } else {
                console.log(response.data, error);

                const message: string = getError(response.data);
                alert(message);
            }
            return { error: true };
        },
        async registration(payload: TRegistrationPayload, state): Promise<PromiceConfirm> {
            let body = cloneObject(payload);
            // body.phone = `+7 ${body.phone}`;
            body.connectionId = state.socketService.connectionId;

            // const {response, error} = await post({route: `api/auth/register`, body});
            const { response, error } = await post({ route: `api/auth/registerV2`, body });
            if (!error && response.status === 200) {
                if (body.sender === EnumSenderSecurity.Telegram) {
                    const data: { id: string, link: string } = response.data;
                    return { link: data.link, id: data.id, error: false };
                } else if (body.sender === EnumSenderSecurity.COMMON_SMS) {
                    const data: { message: string } = response.data;
                    return { message: data.message, error: false };
                }
            } else {
                const message: string = getError(response.data);
                alert(message);
            }
            return { error: true };
        },
        async forgotPassword(payload: TForgotPasswordPayload): Promise<PromiceConfirm> {
            let body: TRegistrationPayload = cloneObject(payload);
            // body.phone = `+7 ${body.phone}`;

            _dispatch.securityService.needChangePassword(true)

            const { response, error } = await post({ route: `api/auth/forgotPass`, body });
            // const {response, error} = await post({route: `api/auth/forgot`, body});
            if (!error && response.status === 200) {
                const data: { link?: string, message?: string } = response.data;
                return { link: data.link, message: data.message, error: false };
            } else {
                const message: string = getError(response.data);
                alert(message);
            }
            return { error: true };
        },
        async confirm(payload: TCodeModel, rootState): Promise<boolean> {
            let body: TCodeModel = cloneObject(payload);
            // if (body.phone.charAt(0) !== "7") body.phone = `+7 ${body.phone}`;

            const { response, error } = await post({ route: `api/auth/confirm`, body });
            if (!error && response.status === 200) {
                await saveState(response.data, _dispatch, rootState.securityService.needChangePassword)
                return true;
            } else {
                const message: string = getError(response.data);
                alert(message);
            }
            return false;
        },
        async changePassword(payload: TChangePassword, rootState): Promise<boolean> {
            const { profile } = rootState;
            let body = cloneObject(payload);
            body.phone = profile.item.phone;

            const { response, error } = await post({ route: `api/auth/changePassword`, body });
            if (!error && response.status === 200) {
                _dispatch.securityService.setData({ needChangePassword: false });
                const data: { message: string } = response.data;
                alert(data.message);
                return true;
            } else {
                const message: string = getError(response.data);
                alert(message);
            }
            return false;
        },
        async logout(payload: object, rootState): Promise<void> {
            DB.destroy(":Security");
            window.location.reload();
        },
        needChangePassword(payload: boolean, rootState): void {
            _dispatch.securityService.setData({ needChangePassword: payload })
        }
    })
})