import React from "react"
import * as Yup from "yup";
import {useFormik} from "formik";
import AuthorizationLayout from "@shared/layouts/authorization";
import {TDefaultPageProps} from "..";
import {Col, Row} from "react-grid-system";
import {isMobile} from "react-device-detect";
import {Link} from "@reach/router";
import {Dispatch} from "@store";
import {connect} from "react-redux";
import {TLoginPayload} from "@store/service/security";
import {CheckboxInput, PasswordInput, PhoneInput} from "@shared/components/formInput";
import {routes} from "AppRouter";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

type Props = {
    fnLogin?: DispatchProps["fnLogin"]
} & TDefaultPageProps

const initialValues: TLoginPayload = {
    phone: "",
    password: "",
    remember: true
},
validationSchema = Yup.object().shape({
    phone: Yup.string().required("Поле обязательно для заполнения"),
    password: Yup.string().required("Поле обязательно для заполнения"),
}),
getErrorByField = (formik: any, field: string) => {
    return formik.errors[field] && formik.touched[field] ? formik.errors[field] : undefined
}

const Login: React.FC<Props> = ({
    fnLogin
}) => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values: TLoginPayload) => {
            if (!executeRecaptcha) return;
            const reCAPTCHA = await executeRecaptcha("ForgotPassword");

            formik.setSubmitting(true)
            if (fnLogin) await fnLogin({...values, reCAPTCHA});
            formik.setSubmitting(false)
        },
    });
    return (
        <AuthorizationLayout title="Авторизация">
            <>
                <div className="form-caption">Авторизация</div>
                {/* <form onSubmit={formik.handleSubmit}>
                    <PhoneInput
                        placeholder="+7 (000) 00 000 00"
                        value={formik.values.phone}
                        onChange={(value: string) => formik.setFieldValue("phone", value, false)}
                        disabled={formik.isSubmitting}
                        error={getErrorByField(formik, "phone")}/>
                    <PasswordInput
                        placeholder="Пароль"
                        value={formik.values.password}
                        onChange={(value: string) => formik.setFieldValue("password", value, false)}
                        disabled={formik.isSubmitting}
                        error={getErrorByField(formik, "password")}/>
                    <CheckboxInput
                        label="Запомнить пароль"
                        checked={formik.values.remember}
                        disabled={false}
                        onChange={(value: boolean) => formik.setFieldValue("remember", value, false)}/>
                    <div className="form-footer">
                        <button 
                            type="submit" disabled={formik.isSubmitting}>
                            Войти
                        </button>
                        <Row>
                            <Col xs={isMobile?12:4}>
                                <Link to={routes.registration}>Регистрация</Link>
                            </Col>
                            <Col xs={isMobile?12:4}>
                                <Link to={routes.login}>Войти по СМС</Link>
                            </Col>
                            <Col xs={isMobile?12:4} className="text-right">
                                <Link to={routes.forgotPassword}>Забыли пароль?</Link>
                            </Col>
                        </Row>
                    </div>
                </form> */}
                <div className="form-m">
                    <PhoneInput
                        placeholder="+7 (000) 00 000 00"
                        value={formik.values.phone}
                        onChange={(value: string) => formik.setFieldValue("phone", value, false)}
                        disabled={formik.isSubmitting}
                        error={getErrorByField(formik, "phone")}/>
                    <PasswordInput
                        placeholder="Пароль"
                        value={formik.values.password}
                        onChange={(value: string) => formik.setFieldValue("password", value, false)}
                        disabled={formik.isSubmitting}
                        error={getErrorByField(formik, "password")}/>
                    <CheckboxInput
                        label="Запомнить пароль"
                        checked={formik.values.remember}
                        disabled={false}
                        onChange={(value: boolean) => formik.setFieldValue("remember", value, false)}/>
                    <div className="form-footer">
                        <button 
                            onClick={() => formik.submitForm()}
                            disabled={formik.isSubmitting && true}>
                            Войти
                        </button>
                        <Row>
                            <Col xs={isMobile?12:4}>
                                <Link to={routes.registration}>Регистрация</Link>
                            </Col>
                            <Col xs={isMobile?12:4}>
                                <Link to={routes.login}>Войти по СМС</Link>
                            </Col>
                            <Col xs={isMobile?12:4} className="text-right">
                                <Link to={routes.forgotPassword}>Забыли пароль?</Link>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        </AuthorizationLayout>
    )
}

const mapDispatch = (d: any) => {
    const dispatch = d as Dispatch;
    return {
        fnLogin: dispatch.securityService.login,
    }
}
type DispatchProps = ReturnType<typeof mapDispatch>
export default connect(null, mapDispatch)(Login);