import * as React from "react";
import * as ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import { Provider } from "react-redux";
import { Demodal } from "demodal";
import { store } from "@store";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { IntlProvider } from "rsuite";
import ruRU from "rsuite/lib/IntlProvider/locales/ru_RU";
import format from "date-fns/format";
import ru from "date-fns/locale/ru";

import AppRouter from "./AppRouter";
import "./styles/index.scss"
import { CsrfTokenContext } from "@shopify/react-csrf";


function formatDate(data: any, formatStr: any) {
    return format(data, formatStr, { locale: ru });
}

ReactDOM.render(
    <Provider store={store}>
        <GoogleReCaptchaProvider reCaptchaKey="6LfyB3IdAAAAAK-YNvwbiNWwkM4No5n3jEr-i2kg">
            <IntlProvider locale={ruRU} formatDate={formatDate}>
                <AppRouter />
            </IntlProvider>
            <Demodal.Container />
        </GoogleReCaptchaProvider>
    </Provider>,
    document.getElementById("root"));

// registerServiceWorker();
