import DefaultLayout from "@shared/layouts/default";
import {RootState} from "@store";
import {TDefaultPageProps} from "pages";
import React from "react";
import {Col, Row, setConfiguration} from "react-grid-system";
import {connect} from "react-redux";
import {isMobile, isIOS, isAndroid} from "react-device-detect";
import {get} from "@shared/network";
import Alert, {EnumAlertType} from "@shared/components/alert";
import {defaultBonus, TBonus} from "@entity/bonus";
import {getError} from "@shared/interaction";

// import PlacesMobile from "./_placesMobile";
// import PlacesDesktop from "./_placesDesktopNew";
// import PlacesDesktop from "./_placesDesktop";
import Places from "./_places";

import {TPlace} from "@entity/place";
import lodash from "lodash";

type TProps = {
    places: RootState["places"],
    security: RootState["securityService"],
} & TDefaultPageProps;

type TState = {
    loading: boolean,
    selected: TBonus,
    bonus: TBonus[],
    error: {
        visible: boolean,
        message: string
    },
}

class BonusProgram extends React.PureComponent<TProps, TState> {

    readonly state = {
        selected: defaultBonus,
        loading: true,
        bonus: [],
        error: {
            visible: false,
            message: ""
        },
    }

    setSelected = (selected: TBonus) => {
        this.setState({selected})
    }

    getPlacesByBonus = (): TPlace | undefined => {
        const {places} = this.props,
        {selected} = this.state;
        return lodash.find(places.items, p => p.id === selected.restaurant);
    }

    componentDidMount = async () => {
        const {security} = this.props,
        {response, error} = await get({route: `api/bonus`, token: security.token});
        if (!error && response.status === 200) {
            const bonus: TBonus[] = response.data;
            let selected: TBonus = defaultBonus;

            const activeBonuse = bonus.filter((b: TBonus) => b.cardNumber);
            if (activeBonuse.length > 0) selected = activeBonuse[0]

            this.setState({loading: false, bonus, selected});
        } else this.setState({
            loading: false,
            error: {
                visible: true,
                message: getError(response.data)
            }
        })
    }

    getACard = (place?: TPlace) => {
        const {security} = this.props;
        if (place) get({route: `api/log/clickBonusCard/${place.id}`, token: security.token});
    }

    render = () => {
        const {places, security} = this.props,
        {selected, loading, error, bonus} = this.state,
        activeBonuse = bonus.filter((b: TBonus) => b.cardNumber),
        place = this.getPlacesByBonus();

        if (isMobile) setConfiguration({gutterWidth: 4});
        return (
            <DefaultLayout title="Бонусная программа" loading={places.loading || loading} layoutClass={!isMobile?"layout-bonus-program":""}>
                 {error.visible ? (
                    <Alert
                        icon="remind"
                        title="Что-то пошло не так. Попробуйте еще раз"
                        description={error.message}
                        type={EnumAlertType.danger}/>
                ) : (
                    <div className="bonus-program">
                        <Places 
                            selected={selected}
                            setSelected={this.setSelected}
                            security={security}
                            bonus={bonus} places={places}/>
                        {activeBonuse.length === 0 || isMobile ? (
                            <></>
                        ) : (
                            <div className="description-card">
                                <div className="card-info">
                                    <div className="place-name">{place?.name}</div>
                                    <div className="card-number">Карта № {selected.cardNumber}</div>
                                    <div>
                                        <Row>
                                            <Col xs={4}>
                                                <div className="info-box">
                                                    <div className="caption red-color">
                                                        {selected.bonusSum}<br />
                                                        бонусов
                                                    </div>
                                                    <div className="signature">На вашей карте</div>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <div className="info-box">
                                                    <div className="caption">{selected.bonusProcent}%</div>
                                                    <div className="signature">Статус карты</div>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <div className="info-box">
                                                    <div className="caption">{selected.bonusRemainder} р.</div>
                                                    <div className="signature">
                                                        До следующего уровня<br />
                                                        осталось потратить
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="link-offer">
                                        {place !== undefined && place.documentsLink !== null && (
                                            <a href={`${place.documentsLink}`} rel="noopener noreferrer" title="Ссылка на оферту бонусной программы" target="_blank">
                                                Ссылка на оферту бонусной программы
                                            </a>
                                        )}
                                    </div>
                                </div>
                                <div className="add-card">
                                    <div className="caption">Добавить бонусную карту в кошелёк</div>
                                        <div className="buttons">
                                            <button className="gpay" type="button" onClick={() => (this.getACard(place), window.open(selected.cardHash + ""))} />
                                            <button className="wallet" type="button" onClick={() => (this.getACard(place), window.open(selected.cardHash + ""))} />
                                        </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </DefaultLayout>
        )
    }
}

const mapState = (state: RootState) => ({
    places: state.places,
    security: state.securityService,
})
export default connect(mapState)(BonusProgram);