import React from "react";
import {Demodal, useModal} from "demodal";
import Rodal from "rodal";
import {Notification} from "react-iconly";
import {isMobile} from "react-device-detect";

type AlertProps = {
    title: string;
    titleCSS?: React.CSSProperties;
    width?: string;
    message?: string;
    messageJSX?: React.ReactNode;
    icon?: React.ReactNode;
    closeText?: string;
}

const AlertModel = Demodal.create(({
    title,
    closeText = "Ок",
}: AlertProps) => {
    const modal = useModal()

    const resolve = (value: boolean) => () => {
        modal.resolve(value)
        modal.close()
    }

    return (
        <Rodal 
            height={300}
            visible={modal.isOpen}
            onClose={modal.close}
            showCloseButton={false}
            className={isMobile? "mobile": undefined}>
            <div 
                className="change-password-modal"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                }}>
                <div className="form">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 15}}>
                        <Notification set="broken" size={90}/>
                    </div>
                    <div 
                        className="form-caption"
                        style={{
                            textAlign: "center",
                            marginBottom: 30,
                            lineHeight: 1,
                            fontSize: 30
                        }}>
                        {title}
                    </div>
                    <div className="button-container">
                        <button 
                            data-badge="inline"
                            type="submit" onClick={resolve(false)}>
                            {closeText}
                        </button>
                    </div>
                </div>
            </div>
        </Rodal>
    )
});

export const Alert = (props: AlertProps) => Demodal.open(AlertModel, props)