import React from "react";
import {Icon} from "rsuite";
import {SVGIcon} from "rsuite/lib/@types/common";
import {IconNames} from "rsuite/lib/Icon";

export enum EnumAlertType {
    success,
    warning,
    danger
}

type TProps = {
    icon?: IconNames | SVGIcon,
    faIcon?: JSX.Element,
    title: string,
    type: EnumAlertType,
    description?: string | JSX.Element
}

const Alert: React.FC<TProps> = ({
    icon, faIcon, title, description, type
}) => {
    return (
        <div className="alert-block">
             <div className={`icon-container ${EnumAlertType[type]}`}>
                 {icon?<Icon icon={icon} className="icon"/>:faIcon?faIcon:<></>}
            </div>
            <div className="title">{title}</div>
            {description && <div className="description">{description}</div>}
        </div>
    )
}

export default Alert;