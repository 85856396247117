import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import { TBonuses, TOrder } from "@entity/order";
import DefaultLayout from "@shared/layouts/default";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { TDefaultPageProps } from "pages";
import { Col, Row } from "react-grid-system";
import { isMobile } from "react-device-detect";
import { get } from "@shared/network";
import { getError } from "@shared/interaction";
import { RootState } from "@store";
import { connect } from "react-redux";
import Alert, { EnumAlertType } from "@shared/components/alert";
import { findById } from "@shared/helpers";
import { CaretLeft, CaretRight } from "react-iconly";

type TProps = {
    security: RootState["securityService"],
    places: RootState["places"]["items"]
} & TDefaultPageProps

type TState = {
    loading: boolean,
    orders: TBonuses[],
    error: {
        visible: boolean,
        message: string
    },
}

const WriteOffsOfBonuses: FC<TProps> = ({
    security, places
}) => {
    const [state, setState] = useState<TState>({
        loading: true,
        orders: [],
        error: {
            visible: false,
            message: ""
        },
    });
    const [page, setPage] = useState({
        amount: 0,
        pageNum: 10,
        index: 1
    })

    const getData = async (pageIndex: number) => {
        const { response, error } = await get({ route: `api/bonus/history/${pageIndex}`, token: security.token });
        if (!error && response.status === 200) {
            setState({ ...state, loading: false, orders: response.data.items });
            setPage({
                index: pageIndex,
                amount: response.data.amount,
                pageNum: response.data.pageNum,
            })
        } else {
            setState({
                ...state,
                loading: false,
                error: {
                    visible: true,
                    message: getError(response.data)
                }
            })
        }
    };

    useEffect(() => {
        if (security.token) getData(1);
    }, [security.token]);

    const { loading, orders, error } = state,
        span = isMobile ? 3 : 3;

    return (
        <DefaultLayout title="Движение бонусов" grayFooter={!loading && !error.visible ? true : false} loading={loading}>
            {error.visible ? (
                <Alert
                    icon="remind"
                    title="Что-то пошло не так. Попробуйте еще раз"
                    description={error.message}
                    type={EnumAlertType.danger} />
            ) : (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: `${isMobile? '100%': '80%'}`,
                    margin: '0 auto'
                }}>
                    <div className="history-order">
                        <Row className="header">
                            <Col className="column" xs={span}>Дата</Col>
                            <Col className="column" xs={span}>Ресторан</Col>
                            <Col className="column" xs={span}>Движение бонусов</Col>
                            <Col className="column" xs={span}>Остаток бонусов</Col>
                            {!isMobile && <Col className="column" xs={span}></Col>}
                        </Row>
                        <div className="rows">
                            {orders.map((order: TBonuses, idx) => {
                                let placesName: string = "", placeLink: string = "",
                                    key = findById(places, order.place);

                                if (key !== -1) {
                                    placesName = places[key].name;
                                }

                                return (
                                    <div className={`row-collapse `} key={idx}>
                                        <Row className="row-main" >
                                            <Col className="column" xs={span}>
                                                {moment(order.date).format("DD.MM.YYYY")}
                                            </Col>
                                            <Col className="column" xs={span}>
                                                <span>{placesName}</span>
                                            </Col>
                                            {order.bonusAdd != 0 ? (
                                                <Col className="column" xs={span}>+{order.bonusAdd}</Col>
                                            ) : (
                                                <Col className="column" style={{ color: '#f75e5e' }} xs={span}>-{order.bonusWriteOff}</Col>
                                            )}
                                            <Col className="column" xs={span}>
                                                {order.bonusAfter}
                                            </Col>

                                        </Row>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="paginate">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={
                                <CaretRight set="bold" primaryColor="#444444" />
                            }
                            initialPage={page.index - 1}
                            onPageChange={page => {
                                getData(page.selected + 1)
                            }}
                            pageRangeDisplayed={isMobile? 3: 5}
                            marginPagesDisplayed={2}
                            pageCount={Math.ceil(page.amount / page.pageNum)}
                            previousLabel={
                                <CaretLeft set="bold" primaryColor="#444444" />
                            } />
                    </div>
                </div>
            )}
        </DefaultLayout>
    )
}

const mapState = (state: RootState) => ({
    security: state.securityService,
    places: state.places.items,
})
export default connect(mapState)(WriteOffsOfBonuses);