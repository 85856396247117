import React, {useEffect} from "react";
import {Router} from "@reach/router"
import {connect} from "react-redux";
import {MetroSpinner} from "react-spinners-kit";
import {RootState} from "@store";

import Login from "pages/security/Login";
import Registration from "pages/security/Registration";
import ForgotPassword from "pages/security/ForgotPassword";
import CurrentOrder from "pages/currentOrder";
import Profile from "pages/profile";
import HistoryOrder from "pages/historyOrder";
import BonusProgram from "pages/bonusProgram";
import WriteOffsOfBonuses from "pages/writeOffsOfBonuses";
import GiftСard from "pages/gift";
import Link from "pages/link";
import LoginV2 from "pages/security/LoginV2";

export const routes = {
    loginPass: "/login/password",
    login: "/login",
    registration: "/registration",
    forgotPassword: "/forgot-password",
    currentOrder: "/",
    historyOrder: "/history",
    writeOffsOfBonuses: "/write-offs-of-bonuses",
    profile: "/profile",
    bonusProgram: "/bonus-program",
    giftСard: '/gift-card',
    link: '/link/:data',
    vlink: '/vlink/:data'
}

type TProps = {
    security: RootState["securityService"]
}

const AppRouter: React.FC<TProps> = ({
    security
}) => {
    useEffect(() => {
        try {
            navigator.serviceWorker.getRegistration('/app').then(reg => {
                reg?.unregister();
                return caches.delete('user-cache');
              }).then(() => {
                console.log('Кеш очищен, Service Worker удалён. Эффективно!');
              });
              
        } catch (error) {
            
        }
    }, [])
    
    if (!security.isReady) return (
        <div className="app-loading">
            <MetroSpinner size={30} color="#dbdbdb"  />
        </div>
    )
    if (!security.isAuthorized) return (
        <Router>
            <Link path={routes.link} link="link"/>
            <Link path={routes.vlink} link="vlink"/>
            <LoginV2 path={routes.login} />
            <Login path={routes.loginPass} />
            <Registration path={routes.registration} />
            <Registration path={`${routes.registration}?phone=:phone`} />
            <ForgotPassword path={routes.forgotPassword} />
        </Router>
    )
    return (
        <Router>
            <Link path={routes.link} link="link"/>
            <Link path={routes.vlink} link="vlink"/>
            <Profile path={routes.profile} />
            <HistoryOrder path={routes.historyOrder} />
            <WriteOffsOfBonuses path={routes.writeOffsOfBonuses} />
            <BonusProgram path={routes.bonusProgram} />
            <CurrentOrder path={routes.currentOrder} />
            <GiftСard path={routes.giftСard} />
        </Router>
    )
}
const mapState = (state: RootState) => ({
    security: state.securityService,
})
export default connect(mapState)(AppRouter);