import {createModel} from "@rematch/core";
import type {RootModel} from "../root";
import {TPlace} from "@entity/place";
import {get} from "@shared/network";

type TModelPlaces = {
    loading: boolean,
    items: TPlace[]
}

export const places = createModel<RootModel>()({
    name: "places",
    state: {
        loading: true,
        items: []
    } as TModelPlaces,
    reducers: {
        update(state: TModelPlaces, payload: object) {
            return {
                ...state,
                ...payload
             };
        },
    },
    effects: (dispatch) => ({
        async init(payload: object, state) {
            const {response, error} = await get({route: `api/auth/restaurants`});
            if (!error && response.status === 200) {
                const items: TPlace[] = response.data;
                dispatch.places.update({loading: false, items})
            } else dispatch.places.update({loading: false})
        }
    })
})