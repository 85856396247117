import React from "react";
import {connect} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Dispatch, RootState} from "@store";
import {CheckboxInput} from "@shared/components/formInput";
import {TSaveProfile} from "@store/models/profile";
import InputMask from "react-input-mask";
import {isMobile} from "react-device-detect";
import moment from "moment";
import {Alert} from "../../@shared/useAlert";

type TProps = {
    profile: RootState["profile"]["item"],
    fnSave: Dispatch["profile"]["save"]
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Поле обязательно для заполнения"),
    email: Yup.string().email("Неверный адрес электронной почты").required("Поле обязательно для заполнения"),
})

const Form: React.FC<TProps> = ({
    profile, fnSave
}) => {
    const formik = useFormik({
        initialValues: {
            ...profile,
            birthDate: profile.birthDate ? moment(profile.birthDate).format("DD.MM.YYYY") : "",
            iAgree: true
        } as TSaveProfile,
        validationSchema,
        onSubmit: async (values: TSaveProfile) => {
            await fnSave(values);
            formik.setSubmitting(false);
            Alert({title: "Данные успешно сохранены!"});
        },
    });
    return (
        <div className="profile-form">
            <form onSubmit={formik.handleSubmit}>
                <div className={`${!isMobile && "row"}`}>
                    <div className={`${!isMobile && "col"}`}>
                        <div className="form-group">
                            <label>Имя</label>
                            <input type="text" name="name" value={formik.values.name} onChange={formik.handleChange} required={true} />
                        </div>
                        <div className="form-group">
                            <label>E-mail</label>
                            <input type="text" name="email" value={formik.values.email} onChange={formik.handleChange} required={true} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label>Телефон</label>
                            <div className="edit-phone">
                                {formik.values.phone}
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Дата рождения</label>
                            <InputMask 
                                mask="99.99.9999"
                                name="birthDate"
                                value={formik.values.birthDate !== null ? formik.values.birthDate : ""}
                                onChange={formik.handleChange}>
                                {(inputProps: any) => <input {...inputProps}/>}
                            </InputMask>
                        </div>
                    </div>
                </div>
                <div className="i-agree-container">
                    <CheckboxInput
                        label="Я согласен с условиями обработки моих персональных данных"
                        disabled={false}
                        checked={formik.values.iAgree}
                        onChange={(value: boolean) => formik.setFieldValue("iAgree", value, false)}/>
                </div>
                <div className="button-container">
                    <button 
                        data-badge="inline"
                        type="submit" disabled={formik.isSubmitting || !formik.values.iAgree}>
                        Сохранить изменения
                    </button>
                </div>
            </form>
        </div>
    )
}

const mapState = (state: RootState) => ({
    profile: state.profile.item
}),
mapDispatch = (d: any) => {
    const dispatch: Dispatch = d;
    return {
        fnSave: dispatch.profile.save,
    }
}
export default connect(mapState, mapDispatch)(Form);