import {useParams} from "@reach/router";
import axios from "axios";
import {TDefaultPageProps} from "pages";
import React, {FC, useEffect, useState} from "react";
import {MetroSpinner} from "react-spinners-kit";


type Props = {
    link: string;
} & TDefaultPageProps

const Link: FC<Props> = ({link}) => {
    const {data} = useParams()
    const [loading, setLoading] = useState(true)

    const onGetPlace = async (data: any) => {
        try {
            const {data: paylaod} = await axios.get(`api/card/${link}/${data}`);
            window.location.href = paylaod.link;
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (data) onGetPlace(data)
    }, [data])

    return (
        <>
            {loading && (
                <div className="loading black">
                    <MetroSpinner size={30} color="#dbdbdb" />
                </div>
            )}
        </>
    )

}

export default Link