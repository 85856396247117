import React from "react"
import * as Yup from "yup";
import {TDefaultPageProps} from "pages";
import AuthorizationLayout from "@shared/layouts/authorization";
import {EnumSenderSecurity, TForgotPasswordPayload} from "@store/service/security";
import {useFormik} from "formik";
import {isMobile} from "react-device-detect";
import {ConfirmationButtons, PhoneInput} from "@shared/components/formInput";
import {Col, Row} from "react-grid-system";
import {Link} from "@reach/router";
// import ReCAPTCHA from "react-google-recaptcha";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

import {Dispatch} from "@store";
import {connect} from "react-redux";
import {routes} from "AppRouter";
import ConfirmModal from "./_confirmModal";
import {cloneObject} from "@shared/helpers";
import ReCAPTCHA from "react-google-recaptcha";

type Props = {
    fnForgotPassword?: DispatchProps["fnForgotPassword"],
    fnNeedChangePassword?: DispatchProps["fnNeedChangePassword"],
} & TDefaultPageProps

const initialValues: TForgotPasswordPayload = {
    phone: "",
    sender: EnumSenderSecurity.COMMON_SMS,
    reCAPTCHA: ""
},
validationSchema = Yup.object().shape({
    phone: Yup.string().required("Поле обязательно для заполнения"),
    sender: Yup.number().min(1, "Обязательно для выбора")
}),
getErrorByField = (formik: any, field: string) => {
    return formik.errors[field] && formik.touched[field] ? formik.errors[field] : undefined
}

const ForgotPassword: React.FC<Props> = ({fnForgotPassword, fnNeedChangePassword}) => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const recaptchaRef = React.createRef<ReCAPTCHA>();
    const [confirmModal, setConfirmModal] = React.useState<{
        message: string,
        visible: boolean
    }>({
        message: "",
        visible: false
    })
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values: TForgotPasswordPayload) => {
            formik.setSubmitting(true)
            if (executeRecaptcha && fnForgotPassword) {
                const reCAPTCHA = await executeRecaptcha("ForgotPassword"),
                data = await fnForgotPassword({...values, reCAPTCHA});

                if (data.error) formik.setSubmitting(false);
                if (data.link) window.open(data.link);
                else if (data.message) {
                    setConfirmModal({
                        message: data.message,
                        visible: true
                    })
                }
                    //  setConfirmModal({
                    //     message: "Yo!",
                    //     visible: true
                    // })
            }
            formik.setSubmitting(false)
        },
    }),
    repeat = async () => {
        if (!fnForgotPassword || !executeRecaptcha) return;

        const reCAPTCHA = await executeRecaptcha("ForgotPassword");

        let values: TForgotPasswordPayload = {
            ...cloneObject(formik.values),
            sender: EnumSenderSecurity.COMMON_SMS,
            reCAPTCHA
        };
        formik.setFieldValue("sender", EnumSenderSecurity.COMMON_SMS, false);

        const data = await fnForgotPassword(values);
        if (data.error) formik.setSubmitting(false);
        if (data.link) window.open(data.link);
        else if (data.message) {
            setConfirmModal({
                message: data.message,
                visible: true
            })
        }
    }

    return (
        <AuthorizationLayout title="Восстановление пароля">
            <>
                <div className="form-caption">Восстановление<br />пароля</div>
                {/* <form onSubmit={formik.handleSubmit}>
                    <PhoneInput
                        placeholder="+7 (000) 00 000 00"
                        value={formik.values.phone}
                        onChange={(value: string) => formik.setFieldValue("phone", value, true)}
                        disabled={formik.isSubmitting}
                        error={getErrorByField(formik, "phone")}/>
                    <ConfirmationButtons
                        value={formik.values.sender}
                        onChange={value => formik.setFieldValue("sender", value, true)}
                        error={getErrorByField(formik, "sender")}/>
                    <div className="form-group mb-5">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            onChange={value => {
                                formik.setFieldValue("reCAPTCHA", value)
                            }}
                            sitekey="6LcVLRobAAAAAJFnOZlhhQiPw0x0H-OW2-Bhyjdt"/>
                    </div>
                    <div className="form-footer">
                        <button 
                            // disabled={formik.values.reCAPTCHA?formik.isSubmitting:true}
                            data-badge="inline"
                            disabled={formik.isSubmitting}
                            type="submit">
                            Отправить
                        </button>
                        <Row>
                            <Col xs={isMobile?12:6}>
                                <Link to={routes.login}>Авторизация</Link>
                            </Col>
                            <Col xs={isMobile?12:6} className="text-right">
                                <Link to={routes.registration}>Регистрация</Link>
                            </Col>
                        </Row>
                    </div>
                </form> */}
                <div className="form-m">
                    <PhoneInput
                        placeholder="+7 (000) 00 000 00"
                        value={formik.values.phone}
                        onChange={(value: string) => formik.setFieldValue("phone", value, true)}
                        disabled={formik.isSubmitting}
                        error={getErrorByField(formik, "phone")}/>
                    <ConfirmationButtons
                        value={formik.values.sender}
                        onChange={value => formik.setFieldValue("sender", value, true)}
                        error={getErrorByField(formik, "sender")}/>
                    {/* <div className="form-group mb-5">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            onChange={value => {
                                formik.setFieldValue("reCAPTCHA", value)
                            }}
                            sitekey="6LcVLRobAAAAAJFnOZlhhQiPw0x0H-OW2-Bhyjdt"/>
                    </div> */}
                    <div className="form-footer">
                        <button 
                            disabled={formik.isSubmitting&&true}
                            data-badge="inline"
                            onClick={() => formik.submitForm()}
                            >
                            Отправить
                        </button>
                        <Row>
                            <Col xs={isMobile?12:6}>
                                <Link to={routes.login}>Авторизация</Link>
                            </Col>
                            <Col xs={isMobile?12:6} className="text-right">
                                <Link to={routes.registration}>Регистрация</Link>
                            </Col>
                        </Row>
                    </div>
                </div>
                {confirmModal.visible && (
                    <ConfirmModal 
                        phone={formik.values.phone}
                        sender={formik.values.sender}
                        visible={confirmModal.visible}
                        caption={confirmModal.message}
                        onRepeat={repeat}
                        onClose={() => {
                            if (fnNeedChangePassword) fnNeedChangePassword(false);
                            recaptchaRef.current?.reset();
                            setConfirmModal({
                                message: "",
                                visible: false
                            });
                        }}/>
                )}
            </>
        </AuthorizationLayout>
    )
}

const mapDispatch = (d: any) => {
    const dispatch = d as Dispatch;
    return {
        fnForgotPassword: dispatch.securityService.forgotPassword,
        fnNeedChangePassword: dispatch.securityService.needChangePassword,
    }
}
type DispatchProps = ReturnType<typeof mapDispatch>
export default connect(null, mapDispatch)(ForgotPassword);