import { routes } from "AppRouter";
import React from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { MetroSpinner } from "react-spinners-kit";
import { TDefaultProps } from "..";
import DesktopHeader from "./extra/header";
import DesktopNav from "./extra/nav";
import ChangePassword from "./extra/changePassword";

type Props = {
    loading: boolean,
    children: JSX.Element,
    grayFooter?: boolean,
    layoutClass?: string,
    nav?: boolean
    overflow?: boolean
} & TDefaultProps;

export type TNav = {
    name: string,
    to: string,
    icon: string
}

const DefaultLayout: React.FC<Props> = (props) => {
    const navigations: TNav[] = [
        { name: "Текущий заказ", to: routes.currentOrder, icon: "currentOrder" },
        { name: "История заказов", to: routes.historyOrder, icon: "historyOrder" },
        { name: "Профиль", to: routes.profile, icon: "profile" },
        { name: "Бонусная программа", to: routes.bonusProgram, icon: "bonusProgram" },
        { name: "Движение бонусов", to: routes.writeOffsOfBonuses, icon: "writeOffBonus" },
        { name: "Подарочные сертификаты", to: routes.giftСard, icon: "gift" },
    ];
    return (
        <div>
            {isMobile ? <Mobile {...props} navigations={navigations} /> :
                <Desktop {...props} navigations={navigations} />}
            <ChangePassword />
        </div>
    )
}
export default DefaultLayout;

const Mobile: React.FC<Props & { navigations: TNav[] }> = ({
    title, navigations, loading,
    grayFooter, children, layoutClass, nav, overflow=false
}) => (
    <div className={`default-mobile-layout ${layoutClass ? layoutClass : ""}`} style={{overflowX: overflow?'scroll':'hidden'}}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <DesktopHeader />
        {nav !== false && <DesktopNav navigations={navigations} isMobile={true} />}
        {isMobile && <h6>{title}</h6>}
        <div className="content">
            {loading ? (
                <div className="loading">
                    <MetroSpinner size={30} color="#dbdbdb" />
                </div>
            ) : children}
        </div>
        <footer>
            <div className="app-store">
                <div className="kasha">
                    Любимая еда в одном приложении
                </div>
                <div className="app-strore-container">
                    <a href="{{$setting->app_store}}" className="app-strore">
                        <div className="bage google"></div>
                    </a>
                    <a href="{{$setting->google_play}}" className="app-strore app-strore-left">
                        <div className="bage apple"></div>
                    </a>
                </div>
            </div>
            <a href="https://restamanagement.ru/post/documents" rel="noopener noreferrer" title="Пользовательское соглашение">Пользовательское соглашение</a>
            <div className="google">
                Этот сайт защищен reCAPTCHA, и применяются the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> и <a href="https://policies.google.com/terms">Terms of Service</a>.
            </div>
        </footer>
    </div>
)
const Desktop: React.FC<Props & { navigations: TNav[], nav?: boolean }> = ({
    title, navigations, loading,
    grayFooter, children, layoutClass, nav, overflow=false
}) => (
    <div className={`default-layout ${layoutClass ? layoutClass : ""}`} style={{overflow: overflow?'scroll':'hidden'}}>
        <Helmet>
            <title>{title}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, width=1440" />
        </Helmet>
        <DesktopHeader nav={nav} />
        {nav !== false && <DesktopNav navigations={navigations} isMobile={false} />}
        <div className="content">
            {loading ? (
                <div className="loading">
                    <MetroSpinner size={30} color="#dbdbdb" />
                </div>
            ) : children}
        </div>
        <footer>
            <div className="links">
                <a href="https://restamanagement.ru/post/documents" rel="noopener noreferrer" target="_blank" title="Пользовательское соглашение">Пользовательское соглашение</a>
            </div>
            <div className="app-store">
                <div className="kasha">
                    Любимая еда в одном приложении
                </div>
                <div className="app-strore-container">
                    <a href="https://play.google.com/store/apps/details?id=ru.restamanagement" className="app-strore">
                        <div className="bage google"></div>
                    </a>
                    <a href="https://apps.apple.com/ru/app/resta-%D0%B2%D0%B0%D1%88%D0%B8-%D0%B2%D0%BA%D1%83%D1%81%D0%BD%D1%8B%D0%B5-%D1%80%D0%B5%D1%81%D1%82%D0%BE%D1%80%D0%B0%D0%BD%D1%8B/id1619258430" className="app-strore app-strore-left">
                        <div className="bage apple"></div>
                    </a>
                </div>
            </div>
            <div className="links google">
                Этот сайт защищен reCAPTCHA, и применяются the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> и <a href="https://policies.google.com/terms">Terms of Service</a>
            </div>
        </footer>
    </div>
)