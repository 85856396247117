import {Models} from "@rematch/core"
import {places} from "./models/places";
import {profile} from "./models/profile";
import {deliveryAddresses} from "./models/deliveryAddresses";
import {securityService} from "./service/security";
import {socketService} from "./service/socket";

export interface RootModel extends Models<RootModel> {
    places: typeof places,
    profile: typeof profile,
    deliveryAddresses: typeof deliveryAddresses,
    securityService: typeof securityService,
    socketService: typeof socketService,
}

export const models: RootModel = {
    profile,
    deliveryAddresses,
    places,
    securityService,
    socketService
}
