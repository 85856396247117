import {createModel} from "@rematch/core";
import type {RootModel} from "../root";
import {TDeliveryAddresses} from "@entity/deliveryAddresses";
import {cloneObject} from "@shared/helpers";
import {confirm, getError, alert} from "@shared/interaction";
import {post} from "@shared/network";

type TModelDeliveryAddresses = {
    loading: boolean,
    items: TDeliveryAddresses[]
}

export const deliveryAddresses = createModel<RootModel>()({
    name: "deliveryAddresses",
    state: {
        loading: true,
        items: []
    } as TModelDeliveryAddresses,
    reducers: {
        update(state: TModelDeliveryAddresses, payload: object) {
            return {...state, ...payload};
        },
    },
    effects: (dispatch) => ({
        async init(payload: object[], state) {
            dispatch.deliveryAddresses.update({loading: false, items: payload})
        },
        async add(payload: TDeliveryAddresses, state): Promise<boolean> {
            const {securityService} = state,
            {response, error} = await post({route: `api/profile/addAddress`, body: payload, token: securityService.token});
            if (!error && response.status === 200) {
                const items: TDeliveryAddresses[] = cloneObject(state.deliveryAddresses.items);
                let item: TDeliveryAddresses = response.data;
                items.push(item);

                await dispatch.deliveryAddresses.update({items})
                return true;
            } else {
                const message: string = getError(response.data);
                alert(message);
            }
            return false;
        },
        async delete(payload: number, state): Promise<void> {
            if (confirm("Удалить адрес доставки?")) {
                const {deliveryAddresses, securityService} = state,
                items: TDeliveryAddresses[] = [];
                deliveryAddresses.items.forEach(item => {
                    if (item.id !== payload) items.push(item);
                    else {
                        post({route: `api/profile/deleteAddress`, body: item, token: securityService.token});
                    }
                })
                await dispatch.deliveryAddresses.update({items})
            }
        }
    })
})