export type TDeliveryAddresses = {
    [index: string]: any,
    id: number,
    userId: number,
    city: string, // Город
    street: string, // Улица
    house: string, // Дом
    flat: string, // Квартира
    intercom: string, // Домофон
    housing: string, // Корпус
    entrance: string, // Подъезд
    floor: number | null, // Этаж
}

export const defaultDeliveryAddresses: TDeliveryAddresses = {
    userId: 0,
    id: 0,
    city: "",
    street: "",
    house: "",
    flat: "",
    intercom: "",
    housing: "",
    entrance: "",
    floor: null,
}