import {init, RematchDispatch, RematchRootState} from "@rematch/core"
import {models, RootModel} from "./root"

export const store = init({
    models,
})

export type Store = typeof store
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>

store.dispatch.places.init({})
store.dispatch.securityService.init({})
store.dispatch.socketService.init({})