import React from "react";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {MetroSpinner} from "react-spinners-kit";
import {isMobile} from "react-device-detect";
import {RootState} from "@store";
import {TDefaultProps} from ".";

type Props = {
    places: RootState["places"],
} & TDefaultProps

const AuthorizationLayout: React.FC<Props> = (props) => {
    if (isMobile) return <Mobile {...props} />
    return <Desktop {...props} />
}

const Mobile: React.FC<Props> = ({title, children}) => (
    <div className="authorization-layout-mobile">
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <header>
            <div className="logo">ЛК</div>
            <div className="header-caption">
                Ваш<br />
                личный кабинет
            </div>
        </header>
        <div className="form-content">
            <div className="form">
                {children}
            </div>
        </div>
        <footer>
            <a href="https://restamanagement.ru/post/documents" rel="noopener noreferrer" title="Пользовательское соглашение">Пользовательское соглашение</a>
            <div className="google">
                Этот сайт защищен reCAPTCHA, и применяются the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> и <a href="https://policies.google.com/terms">Terms of Service</a>.
            </div>
        </footer>
    </div>
)

const Desktop: React.FC<Props> = ({title, places, children}) => {
    return (
        <div className="authorization-layout">
            <Helmet>
                <title>{title}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, width=1440" />
            </Helmet>
            <div className="form-content">
                <div className="logo">ЛК</div>
                <a className="rm-logo" href="https://restamanagement.ru/" target="_blank" title="Resta Management" rel="noopener noreferrer"/>
                <div className="form">
                    {children}
                </div>
            </div>
            <div className="places-content">
                <div className="places-caption">
                    Ваши<br />
                    рестораны
                </div>
                <div className="places-list">
                    {places.loading ? (
                        <div className="loading">
                            <MetroSpinner size={30} color="#dbdbdb"  />
                        </div>
                    ):(
                        <ul>
                            {places.items.map((place, idx) => {
                                return (
                                    <li key={idx}>
                                        <a href={place.link} title={place.name} target="_blank" rel="noopener noreferrer">
                                            {place.name}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    )}
                </div>
                <div className="places-footer">
                    <div className="places-footer-caption">Ваш личный кабинет</div>
                    <div>
                        <a href="https://restamanagement.ru/post/documents" target="_blank" rel="noopener noreferrer" title="Пользовательское соглашение">Пользовательское соглашение</a>
                        <div className="google">
                            Этот сайт защищен reCAPTCHA, и применяются the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> и <a href="https://policies.google.com/terms">Terms of Service</a>.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapState = (state: RootState) => ({
    places: state.places,
})
export default connect(mapState)(AuthorizationLayout);