import React from "react";
import Rodal from "rodal";
import {useFormik} from "formik";
import {useDispatch} from "react-redux";
import {Dispatch} from "@store";
import {EnumSenderSecurity} from "@store/service/security";
import {isMobile} from "react-device-detect";
import styled from "styled-components";

type TConfirmModal = {
    phone: string,
    visible: boolean,
    caption: string,
    sender: EnumSenderSecurity,
    onClose: () => void;
    onRepeat: () => void;
}

export type TCodeModel = {
    phone: string,
    code: string
}

const CodeInput = styled.input`
    text-align: center;
    height: 46px!important;
    font-size: 18px!important;
    font-weight: 700!important;
    margin: 15px 0 0 0;
`

const ConfirmModal: React.FC<TConfirmModal> = ({
    visible, caption, phone, sender, onClose, onRepeat
}) => {
    const dispatch = useDispatch<Dispatch>();
    const formik = useFormik({
        initialValues: {
            phone, 
            code: "",
        } as TCodeModel,
        onSubmit: async (values: TCodeModel) => {
            formik.setSubmitting(true)
            if (! await dispatch.securityService.confirm(values)) formik.setSubmitting(false)
        },
    });
    return (
        <Rodal visible={visible} onClose={onClose} showCloseButton={true} height={sender === EnumSenderSecurity.SMS? 310 :undefined}
        className={isMobile? "mobile": undefined}>
            {visible && (
                <div className="confirm-container">
                    {sender === EnumSenderSecurity.SMS?(
                        <div className="caption">Сейчас вам поступит входящий звонок от робота, отвечать на него не нужно. Укажите последние 4 цифры в поле "Код".</div>
                    ): <div className="caption">{caption}</div>}
                    <div>
                        {/*
                            <ReactCodeInput
                                type='number' 
                                disabled={formik.isSubmitting}
                                autoFocus={true}
                                className="pincode"
                                fields={4}
                                onChange={(value: string) => {
                                    if (value.length === 4) {
                                        formik.setFieldValue("code", value);
                                        formik.submitForm();
                                    }
                                }} />
                        */}
                            <CodeInput 
                                type="number"
                                autoFocus={true}
                                disabled={formik.isSubmitting}
                                value={formik.values.code}
                                onChange={e => {
                                    const value = e.target.value;
                                    formik.setFieldValue("code", value)
                                    if (value && value.length === 4) {
                                        formik.submitForm();
                                    }
                                }}
                                />
                    </div>
                    {sender === EnumSenderSecurity.SMS? (
                        <div className="caption">
                            Если у вас возникли сложности с авторизация по звонку, вы можете воспользоваться авторизация по SMS" - <button onClick={onRepeat}>Запросить SMS код</button>
                        </div>
                    ): undefined}
                </div>
            )}
        </Rodal>
    )
}

export default ConfirmModal;