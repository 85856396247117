import React from "react"
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthorizationLayout from "@shared/layouts/authorization";
import { TDefaultPageProps } from "..";
import { Col, Row } from "react-grid-system";
import { isMobile } from "react-device-detect";
import { Link, useNavigate } from "@reach/router";
import { Dispatch } from "@store";
import { connect } from "react-redux";
import { TLoginPayload, TLoginPayloadV2 } from "@store/service/security";
import { CheckboxInput, PasswordInput, PhoneInput } from "@shared/components/formInput";
import { routes } from "AppRouter";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ConfirmModal from "./_confirmModal";
import { useRegistration } from "@storeZusland/useRegistration";

type Props = {
    fnLoginV2?: DispatchProps["fnLoginV2"]
} & TDefaultPageProps

const initialValues: TLoginPayloadV2 = {
    phone: "",
    agreement: true,
},
    validationSchema = Yup.object().shape({
        phone: Yup.string().required("Поле обязательно для заполнения"),
    }),
    getErrorByField = (formik: any, field: string) => {
        return formik.errors[field] && formik.touched[field] ? formik.errors[field] : undefined
    }

const LoginV2: React.FC<Props> = ({
    fnLoginV2
}) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const navigate = useNavigate();
    const setPhone = useRegistration(state => state.setPhone)

    const [confirmModal, setConfirmModal] = React.useState<{
        message: string,
        visible: boolean
    }>({
        message: "",
        visible: false
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values: TLoginPayloadV2) => {
            if (executeRecaptcha && fnLoginV2) {
                const reCAPTCHA = await executeRecaptcha("ForgotPassword");

                formik.setSubmitting(true)
                const data = await fnLoginV2({ ...values, reCAPTCHA });
                if (data.message) {
                    setConfirmModal({
                        message: data.message,
                        visible: true
                    })
                } else if (data.error && data.needToRegister) {
                    setPhone(values.phone)
                    navigate("/registration")
                }
                formik.setSubmitting(false)
            }
        },
    });

    const repeat = () => {

    }
    return (
        <AuthorizationLayout title="Авторизация">
            <>
                <div className="form-caption">Авторизация</div>
                <div className="form-m">
                    <Row>
                        <Col xs={12}>
                            <div style={{ marginBottom: 5 }}>Введите номер телефона</div>
                        </Col>
                    </Row>
                    <PhoneInput
                        placeholder="+7 (000) 00 000 00"
                        value={formik.values.phone}
                        onChange={(value: string) => formik.setFieldValue("phone", value, false)}
                        disabled={formik.isSubmitting}
                        name="tel"
                        error={getErrorByField(formik, "phone")} />
                    <CheckboxInput
                        label={(
                            <>
                                Согласен с <a href="https://restamanagement.ru/post/documents" target="_blank">обработкой персональных данных</a>
                            </>
                        )}
                        checked={formik.values.agreement}
                        disabled={formik.isSubmitting}
                        onChange={(value: boolean) => formik.setFieldValue("agreement", value, false)} />
                    <div className="form-footer">
                        <button
                            data-badge="inline"
                            disabled={formik.isSubmitting || !formik.values.agreement}
                            onClick={() => formik.submitForm()}
                            type="submit">
                            Войти
                        </button>
                        <Row>
                            <Col xs={12} style={{ textAlign: "center" }}>
                                <Link to={routes.loginPass} style={{ fontSize: 14 }}>Войти по паролю</Link>
                            </Col>
                        </Row>
                    </div>
                </div>
                {confirmModal.visible && (
                    <ConfirmModal
                        phone={formik.values.phone}
                        visible={confirmModal.visible}
                        caption={confirmModal.message}
                        sender={4}
                        onClose={() => {
                            setConfirmModal({
                                message: "",
                                visible: false
                            });
                        }}
                        onRepeat={repeat} />
                )}
            </>
        </AuthorizationLayout>
    )
}

const mapDispatch = (d: any) => {
    const dispatch = d as Dispatch;
    return {
        fnLogin: dispatch.securityService.login,
        fnLoginV2: dispatch.securityService.loginV2,
    }
}
type DispatchProps = ReturnType<typeof mapDispatch>
export default connect(null, mapDispatch)(LoginV2);